import { MetaDefinition } from '@angular/platform-browser';
import { Center } from '../../marketplace/models/center';

interface MetaContent {
  name?: string;
  property?: string;
  content?: string;
}

export const META_TAG = {
  title:
    'Réserver un terrain de Tennis, Badminton, Squash et Padel à Paris, Lyon, Lille, Marseille, Montpellier et Bordeaux',
  tags: [
    {
      name: 'description',
      content:
        'Réserver des terrains de Tennis, Badminton, Squash et Padel sur Paris, Lyon, Lille, Marseille, Montpellier et Bordeaux'
    },
    {
      name: 'msapplication-TileImage',
      content: '/assets/images/ms-icon-144x144.png'
    },
    {
      name: 'apple-itunes-app',
      content: 'app-id=1159088112, app-argument=https://www.anybuddyapp.com'
    },
    {
      property: 'og:title',
      content: 'Anybuddy - Réserver un court de Tennis instantanément'
    },
    {
      property: 'og:description',
      content: 'Réserve un terrain de tennis en 3 clics au meilleur prix'
    },
    { property: 'og:url', content: 'https://www.anybuddyapp.com/' },
    { property: 'og:site_name', content: 'Anybuddy' },
    {
      property: 'og:image',
      content: 'https://www.anybuddyapp.com/assets/images/anybuddy-logo.png'
    },
    { property: 'og:image:width', content: '2960' },
    { property: 'og:image:height', content: '2000' },
    { property: 'fb:app_id', content: '303254103213707' },
    {
      name: 'twitter:description',
      content:
        'Réserve un terrain de tennis, padel, squash ou badminton en 3 clics au meilleur prix'
    },
    {
      name: 'twitter:title',
      content: "Anybuddy - L'appli de réservation de sports de raquette"
    },
    { name: 'twitter:site', content: '@anybuddy_app' },
    {
      name: 'twitter:image',
      content: 'https://www.anybuddyapp.com/assets/images/anybuddy-logo.png'
    },
    { name: 'twitter:creator', content: '@anybuddy_app' },
    { name: 'theme-color', content: '#1976d2' }
  ],
  links: [
    {
      rel: 'apple-touch-icon',
      sizes: '57x57',
      href: '/assets/icons/apple-icon-57x57.png'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '60x60',
      href: '/assets/icons/apple-icon-60x60.png'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '72x72',
      href: '/assets/icons/apple-icon-72x72.png'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '76x76',
      href: '/assets/icons/apple-icon-76x76.png'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '114x114',
      href: '/assets/icons/apple-icon-114x114.png'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '120x120',
      href: '/assets/icons/apple-icon-120x120.png'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '144x144',
      href: '/assets/icons/apple-icon-144x144.png'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      href: '/assets/icons/apple-icon-152x152.png'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/assets/icons/apple-icon-180x180.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '192x192',
      href: '/assets/icons/android-icon-192x192.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/assets/icons/favicon-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '96x96',
      href: '/assets/icons/favicon-96x96.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/assets/icons/favicon-16x16.png'
    },
    { rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }
  ]
};

export function tagToMetaDefinition(tag: MetaContent): MetaDefinition {
  const meta: MetaDefinition = {};
  if (tag.name) {
    meta.name = tag.name;
  }
  if (tag.property) {
    meta.property = tag.property;
  }
  if (tag.content) {
    meta.content = tag.content;
  }
  return meta;
}

export function generateMetaTag(center: Center): any {
  if (!center) {
    return META_TAG;
  }
  return {
    title: generateTitle(center),
    tags: [
      { name: 'description', content: generateTitle(center) },
      {
        name: 'msapplication-TileImage',
        content: getCenterLogo(center)
      },
      {
        property: 'og:title',
        content: center.name + ' - Réserver un terrain de sport instantanément'
      },
      {
        property: 'og:description',
        content: 'Réserve un terrain de sport en 3 clics au meilleur prix'
      },
      { property: 'og:url', content: center.website },
      { property: 'og:site_name', content: center.name },
      { property: 'og:image', content: getCenterLogo(center) },
      { property: 'og:image:width', content: '2960' },
      { property: 'og:image:height', content: '2000' },
      { name: 'theme-color', content: '#1976d2' }
    ],
    links: [
      {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '57', '57')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '60x60',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '60', '60')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '72', '72')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '76', '76')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '114', '114')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '120', '120')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '144', '144')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '152', '152')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '180', '180')
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '192x192',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '192', '192')
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '32', '32')
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '96', '96')
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '16', '16')
      },
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '16', '16')
      }
    ]
  };
}

export function generateMetaTagMarketplace(center: Center): any {
  if (!center) {
    return META_TAG;
  }
  return {
    title: generateTitle(center),
    tags: [
      { name: 'description', content: generateTitle(center) },
      {
        name: 'msapplication-TileImage',
        content: getCenterLogo(center)
      },
      {
        property: 'og:title',
        content: center.name + ' - Réserver un terrain de sport instantanément'
      },
      {
        property: 'og:description',
        content: 'Réserve un terrain de sport en 3 clics au meilleur prix'
      },
      { property: 'og:url', content: center.website },
      { property: 'og:site_name', content: center.name },
      { property: 'og:image', content: getCenterLogo(center) },
      { property: 'og:image:width', content: '2960' },
      { property: 'og:image:height', content: '2000' },
      { name: 'theme-color', content: '#1976d2' }
    ],
    links: [
      {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '57', '57')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '60x60',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '60', '60')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '72', '72')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '76', '76')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '114', '114')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '120', '120')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '144', '144')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '152', '152')
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '180', '180')
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '192x192',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '192', '192')
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '32', '32')
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '96', '96')
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '16', '16')
      },
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: getSizedCenterLogo(center.profilePhoto['192x192'], '16', '16')
      }
    ]
  };
}

function generateTitle(center: Center): string {
  if (center.metaTitle) {
    return center.metaTitle;
  }
  return (
    'Réserver un terrain de ' +
    center.activities
      .map((activity) => {
        activity.name;
      })
      .join(', ')
  );
}

function getCenterLogo(center: Center): string {
  const originalUrl = center.profilePhoto['192x192'];
  if (originalUrl) {
    return getSizedCenterLogo(originalUrl, '200');
  }
  return '';
}

function getSizedCenterLogo(
  originalUrl: string,
  width?: string,
  height?: string
): string {
  if (!originalUrl) return '';
  if (['16', '32'].includes(width) && width === height) {
    return '/assets/icons/favicon_.png';
  }
  const replace = `/f_auto,q_auto${width ? ',w_' + width : ''}${
    height ? ',h_' + height : ''
  }`;
  return originalUrl.replace(
    '/w_192,h_192,c_fill/c_scale,w_auto/dpr_auto',
    replace
  );
}
