<ng-container>
  <app-loader *ngIf="!dataAvailable"></app-loader>
</ng-container>

<div class="container" (window:resize)="widthSize($event)" *ngIf="dataAvailable && !resaSuccess">
  <header>
    <div class="header-svg">
      <svg [routerLink]="['/account']" routerLinkActive="active" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/></svg>
    </div>
  </header>

  <div class="title">
    <h1>{{ 'my_resa.your_resa' | translate }}</h1>
    <hr>
  </div>

  <div class="content">
    <div class="alert alert-danger">
      {{ 'my_resa.error' | translate }}
    </div>
  </div>
</div>

<div class="container" (window:resize)="widthSize($event)"  *ngIf="dataAvailable && resaSuccess">
  <header>
    <div class="header-svg">
      <svg [routerLink]="['/account']" routerLinkActive="active" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/></svg>
    </div>
  </header>

  <div class="title">
    <h1>{{ 'match.yourMatch' | translate }}</h1>
    <hr>
  </div>

  <div class="content">
    <div class="left-container">
      <!-- Informations terrains -->
      <div class="informations-container">
        <h2>{{match.centerService.centerName }}</h2>
        <p>{{match.centerService.addressFMT }}</p>

        <h2>{{ match.centerService.serviceName | titlecase }}</h2>

        <div class="facilities" *ngIf="facility">
          <p *ngIf="facility.facilitySurface !=='unknown'">{{facility.facilitySurface}}</p>
          <p *ngIf="facility.facilitySurface !=='unknown'"> - </p>
          <p *ngIf="facility.isIndoor">{{ 'my_resa.indoor' | translate }}</p>
          <p *ngIf="!facility.isIndoor">{{ 'my_resa.outdoor' | translate }}</p>
          <p *ngIf="facility.light"> - </p>
          <p *ngIf="facility.light">{{ 'my_resa.light' | translate }}</p>
        </div>
      </div>
      <!-- Fin Informations terrains -->

      <!-- Résa date -->
      <div class="resa-date-container">
        <p class="date">{{ match.dateFMT }}</p>
        <p class="hours">{{ match.timeFMT }}</p>
      </div>
      <!-- Fin Résa date -->

      <!-- Résa validée ou annulée -->
      <div *ngIf="match.status === status.Confirmed" class="resa-valid">
        <p>{{ 'match.matchConfirmed' | translate }}</p>
        <svg class="valid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="rgba(255,255,255,1)"/></svg>
      </div>

      <div *ngIf="match.status === status.Cancelled || match.status === status.Init" class="resa-valid">
        <p *ngIf="match.status === status.Cancelled">{{ 'match.matchCancelled' | translate }}</p>
        <p *ngIf="match.status === status.Init">{{ 'match.reservation_fail' | translate }}</p>
        <svg class="denied" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" fill="rgba(255,255,255,1)"/></svg>
      </div>

      <div *ngIf="match.status === status.AwaitingValidation" class="resa-valid">
        <p>{{ 'match.matchAwaiting' | translate }}</p>
        <svg class="valid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z" fill="rgba(255,255,255,1)"/></svg>
      </div>

      <div *ngIf="match.status === status.AwaitingPlayers" class="resa-valid">
        <p>{{ 'match.matchAwaitingPlayer' | translate }}</p>
        <svg class="valid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z" fill="rgba(255,255,255,1)"/></svg>
      </div>


      <!-- Fin Résa validée ou annulée -->


      <hr *ngIf="reservation && reservation.importantMsg">

      <div class="important-info-container mt-3" *ngIf="reservation &&reservation.importantMsg">
        <div class="title">
          <h1>{{'my_resa.important_msg' | translate}}</h1>
        </div>
        <div class="content">
          <p class="text">
            <span class="subtitle">{{reservation.importantMsg.title}} :</span>
            {{reservation.importantMsg.content}}
          </p>
        </div>
      </div>

      <!-- Instructions -->
      <div *ngIf="reservation && reservation.instructions.length !== 0 && reservation.importantMessage" class="instructions-container">
        <hr>
        <h2>{{ 'my_resa.directions_title' | translate }}</h2>
        <p>{{reservation.importantMessage}}</p>
      </div>
      <!-- Fin Instructions -->

      <div class="share-container" *ngIf="link">
        <div *ngIf="!this.shareClicking" class="btn-partage" [cdkCopyToClipboard]="getLink()" (click)="toast()" label="Show" >
          <span>{{ "match.share" | translate}}</span>
        </div>
        <div *ngIf="this.shareClicking" class="btn-partage2" [cdkCopyToClipboard]="getLink()" (click)="toast()" label="Show" >
          <span>{{ "match.copiedLink" | translate}}</span>
        </div>
      </div>

      <hr>

      <!-- Section Match et joueur-->
      <div class="game-player">
        <div class="row d-flex justify-content-between">
          <div class="col title ">
            <span>{{ "match.matchAndPlayers" | translate}}</span>
          </div>
          <div class="col config">
            <button class="button-config" disabled>{{ configMatch }}</button>
          </div>
        </div>
        <div class="row teams d-flex justify-content-center" *ngIf="match.nbPlayersMax >1">
          <div class="col team1">
            <div class="row title">{{ "match.teamA" | translate}}</div>

            <div class="row" *ngFor="let player of playersTeamA">
              <div class="col">
                <div class="row imagePlayer">
                  <section class="hexagon-gallery">
                    <div class="hex" *ngIf="!this.isWhiteLabel">
                      <img [src]="player.pictureUrl ? player.pictureUrl : defaultPicture " alt="some">
                    </div>
                    <div class="hex hex-player" *ngIf="this.isWhiteLabel"></div>
                  </section>
                </div>
                <div class="row firstName">
                  <span>{{ player.firstName }}</span>
                </div>
              </div>
            </div>

            <div class="row" *ngFor="let player of [].constructor(missingPlayerTeamA)">
              <div class="col">
                <div class="row imagePlayer">
                  <section class="hexagon-gallery">
                    <div class="hex hex-no-player">
                      <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="50" width="50" fill="rgba(255,255,255,1)"><path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path></svg>
                    </div>
                  </section>
                </div>
                <div class="row firstName">
                  <span></span>
                </div>
              </div>
            </div>
          </div>

          <div class="col team2">
            <div class="row title">{{ "match.teamB" | translate}}</div>
            <div class="row" *ngFor="let player of playersTeamB">
              <div class="col">
                <div class="row imagePlayer">
                  <section class="hexagon-gallery">
                    <div class="hex" *ngIf="!this.isWhiteLabel">
                      <img [src]="player.pictureUrl ? player.pictureUrl : defaultPicture " alt="some">
                    </div>
                    <div class="hex hex-player" *ngIf="this.isWhiteLabel"></div>
                  </section>
                </div>
                <div class="row firstName">
                  <span>{{ player.firstName }}</span>
                </div>
              </div>
            </div>

            <div class="row" *ngFor="let player of [].constructor(missingPlayerTeamB)">
              <div class="col">
                <div class="row imagePlayer">
                  <section class="hexagon-gallery">
                    <div class="hex hex-no-player">
                      <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="50" width="50" fill="rgba(255,255,255,1)"><path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path></svg>
                    </div>
                  </section>
                </div>
                <div class="row firstName">
                  <span></span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- Fin Section Match et joueur-->

      <hr>

      <!-- Section Rejoindre Match-->
      <div class="share-container" *ngIf="this.nextAction && !hasJoin">
        <h2 *ngIf="this.nextAction == 'PAY'">{{ "match.pay" | translate}}</h2>
        <h2 *ngIf="this.nextAction == 'JOIN'">{{ "match.join" | translate}}</h2>
        <button class="btn-partage" *ngIf="this.nextAction == 'PAY'" (click)="payeTaPlace()" [disabled]="isLoading">
         {{ "match.payShare" | translate}}
        </button>
        <button class="btn-partage" *ngIf="this.nextAction == 'JOIN'" (click)="joinMatch()" [disabled]="isLoading">
          {{ "match.joinMatch" | translate}}
        </button>
      </div>
      <hr *ngIf="this.nextAction && !hasJoin">
      <!-- Fin Section Rejoindre Match-->

      <!-- Localisation google map -->
      <div class="localisation-container">
        <h2>{{ 'my_resa.location_title' | translate }}</h2>
        <p>{{match.centerService.centerName}}</p>
        <p>{{match.centerService.addressFMT }}</p>
      </div>
      <!-- google map -->
      <app-localisation *ngIf="center" [center]="center" [googleMapLink]="googleMapLink" [hideTitle]="hideTitle"></app-localisation>
      <!-- Fin Localisation google map -->


      <!-- Récapitulatif de la réservation mobile -->
      <div class="recap-resa-container" *ngIf="isLittleWidth && this.status_match != 'awaiting_player'">
        <hr>
        <h1>{{ 'match.recap' | translate }}</h1>
        <!-- Si pas de choix de place -->

        <div class="resa-info">
          <div class="resa">
            <p class="site">{{ 'match.priceMatch' | translate }}</p>
          </div>
          <p class="price">{{ matchTotalPrice }}</p>
        </div>

        <hr>

        <div class="resa-info" *ngIf="!places || !places.length">
          <div class="resa">
            <p class="site" *ngIf="reservation">{{reservation.name}}</p>
            <p class="site" *ngIf="!reservation">{{ 'my_resa.site' | translate }} {{match.centerService.activity | titlecase}}</p>
          </div>
          <p class="price">{{ price ? (price  / 100 | currency:"EUR") : matchTotalPrice}}</p>
        </div>

        <!-- Places jeunes et adultes achetés -->
        <div class="resa-info" *ngIf="places && places.length">
          <div class="resa">
            <p class="resa-title">{{ 'my_resa.players' | translate | titlecase}}</p>
            <div class="price-info" *ngFor="let place of places">
              <p class="players">{{ place.name }} <strong>x {{place.nbPlace}}</strong> </p>
              <p class="price">{{ place.price / 100 | currency:"EUR"}}</p>
            </div>
          </div>
        </div>

        <div class="promo-container" *ngIf="vouchers && vouchers.length">
          <p class="price-title">{{ 'my_resa.promo_code' | translate }}</p>
          <div  *ngFor="let voucher of vouchers">
            <div class="promo" *ngIf="voucher.discountAmount > 0">
              <p class="promo-name">{{voucher.name}}</p>
              <p class="price">- {{ voucher.discountAmount / 100 | currency:'EUR':'symbol':'1.2-2':'fr' }}</p>
            </div>
          </div>
        </div>

        <div class="fee-container" *ngIf="reservation && reservation.serviceFee !== undefined && reservation.serviceFee !== null">
          <div class="fee-content">
            <div class="fee-label">
              <div class="fee-space" *ngIf="reservation.serviceFee > 0" (click)="openFeeInfoModal(feeInfo)">
                <p class="title-fee">{{ 'my_resa.serviceFee' | translate }}</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"/></svg>
              </div>
              <div class="fee-space" *ngIf="reservation.serviceFee===0 && reservation.serviceFeeText !== null && reservation.serviceFeeText !== ''">
                <p class="title-fee" style="cursor: auto">{{ reservation.serviceFeeText }}</p>
              </div>
            </div>
            <div class="fee-price" *ngIf="reservation.serviceFee > 0">
              <p class="price">+ {{reservation.serviceFee / 100 | currency:"EUR"}}</p>
            </div>
          </div>
        </div>

        <div class="total-container" *ngIf="reservation">
          <p class="price-title">{{ 'match.totalPriceResa' | translate }}</p>
          <p class="price">{{ price  / 100 | currency:"EUR" }}</p>
        </div>
        <div class="total-container" *ngIf="!reservation">
          <p class="price-title">{{ 'match.totalPriceMatch' | translate }}</p>
          <p class="price">{{ matchTotalPrice }}</p>
        </div>

        <div *ngIf="reservation && canShowDownloadInvoice()">
          <button class="btn-download" (click)="downloadInvoice()">{{'my_resa.downloadInvoice' | translate}}</button>
        </div>
      </div>
      <!-- Fin Récapitulatif de la réservation -->

      <!-- Quitter le match -->
      <div *ngIf="hasJoin">
        <hr>
        <h1>{{'match.quit' | translate}}</h1>
        <div class="share-container" >
          <div class="btn-quitter" (click)="openLeaveMatchModal(leaveMatchModal)" label="Show" >
            <span>{{'match.quitMatch' | translate}}</span>
          </div>
        </div>
      </div>
      <!--Fin Quitter le match -->

    </div>

    <div class="right-container" *ngIf="!isLittleWidth && this.status_match != 'awaiting_player'">
      <!-- Récapitulatif de la réservation mobile -->
      <div class="recap-resa-container">
        <h1>{{ "match.recap" | translate}}</h1>


        <div class="resa-info">
          <div class="resa">
            <p class="site">{{ 'match.priceMatch' | translate }}</p>
          </div>
          <p class="price">{{ matchTotalPrice }}</p>
        </div>

        <hr>

        <!-- Si pas de choix de place -->
        <div class="resa-info" *ngIf="!places || !places.length">
          <div class="resa">
            <p class="site" *ngIf="reservation">{{reservation.name}}</p>
            <p class="site" *ngIf="!reservation">{{ 'my_resa.site' | translate }} {{match.centerService.activity | titlecase}}</p>
          </div>
          <p class="price" *ngIf="reservation">{{ reservation.priceCents / 100 | currency:"EUR" }}</p>
          <p class="price" *ngIf="!reservation">{{ matchTotalPrice }}</p>
        </div>

        <!-- Places jeunes et adultes achetés -->
        <div class="resa-info" *ngIf="places && places.length">
          <div class="resa">
            <p class="resa-title">{{ 'my_resa.players' | translate | titlecase}}</p>
            <div class="price-info" *ngFor="let place of places">
              <p class="players">{{ place.name }} x{{place.nbPlace}}</p>
              <p class="price">{{ place.price / 100 | currency:"EUR"}}</p>
            </div>
          </div>
        </div>

        <div class="promo-container" *ngIf="vouchers && vouchers.length">
          <p class="price-title">{{ 'my_resa.promo_code' | translate }}</p>
          <div class="promo" *ngFor="let voucher of vouchers">
            <p *ngIf="voucher.discountAmount > 0" class="promo-name">{{voucher.name}}</p>
            <p *ngIf="voucher.discountAmount > 0" class="price">- {{ voucher.discountAmount / 100 | currency:'EUR':'symbol':'1.2-2':'fr' }}</p>
          </div>
        </div>

        <div class="fee-container" *ngIf="reservation && reservation.serviceFee !== undefined && reservation.serviceFee !== null">
          <div class="fee-content">
            <div class="fee-label">
              <div class="fee-space" *ngIf="reservation.serviceFee > 0" (click)="openFeeInfoModal(feeInfo)">
                <p class="title-fee">{{ 'my_resa.serviceFee' | translate }}</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"/></svg>
              </div>
            </div>
            <div class="fee-price" *ngIf="reservation.serviceFee > 0">
              <p class="price">+ {{reservation.serviceFee / 100 | currency:"EUR"}}</p>
            </div>
          </div>
        </div>

        <hr>

        <div class="total-container" *ngIf="reservation">
          <p class="price-title">{{ 'match.totalPriceResa' | translate }}</p>
          <p class="price">{{ price/ 100 | currency: "EUR" }}</p>
        </div>
        <div class="total-container" *ngIf="!reservation">
          <p class="price-title">{{ 'match.totalPriceMatch' | translate }}</p>
          <p class="price">{{ matchTotalPrice }}</p>
        </div>

        <div *ngIf="reservation && canShowDownloadInvoice()">
          <button class="btn-download" (click)="downloadInvoice()">{{'my_resa.downloadInvoice' | translate}}</button>
        </div>
      </div>
      <!-- Fin Récapitulatif de la réservation -->
      <div class="important-info-container mt-3" *ngIf="reservation && reservation.importantMsg && reservation.importantMsg !== null ">
        <div class="title">
          <h1>{{ "match.important" | translate}}</h1>
        </div>
        <div class="content">
          <p class="text">
            <span class="subtitle">{{reservation.importantMsg.title}} :</span>
            {{reservation.importantMsg.content}}
          </p>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #feeInfo let-modal style="height: auto">
  <div class="modal-header">
    <div class="col" style="display: flex">
      <h2>{{ 'my_resa.serviceFee' | translate }}</h2>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col">
      <p class="fee-modal-text">{{reservation.serviceFeeText}}</p>
    </div>
  </div>
</ng-template>

<ng-template #leaveMatchModal let-modal>
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="container">
      <ngb-alert *ngIf="isCancel" type="success">{{ 'my_bookings.success_resa_cancel' | translate }}</ngb-alert>
      <div class="title">
        <h4>{{ 'my_bookings.confirm_cancel_reservation' | translate }}</h4>
      </div>
      <div class="btn-confirm">
        <button class="button button-denied" (click)="modal.dismiss('Cross click')"> {{ 'my_bookings.no' |
          translate }} </button>
        <button class="button button-confirm" (click)="leaveMatch(modal)"> {{
          'my_bookings.yes' | translate }} </button>
      </div>
      <div *ngIf="isError" class="error-cancel">
        <p>{{notCancelableError}}</p>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #errorInvoice let-modal style="height: auto">
  <div class="modal-header">
    <div class="col" style="display: flex">
      <h2>{{ 'my_resa.invoice' | translate }}</h2>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body">
      <div class="col w-100">
        <p>{{ 'my_resa.errorInvoice' | translate}}</p>
        <p class="text-center break-word error">{{invoiceErrorMsg}}</p>
      </div>
    </div>
  </div>
</ng-template>
