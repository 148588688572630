import { Component } from '@angular/core';
import { Center } from 'src/app/marketplace/models/center';
import { WhiteLabelCenterService } from '../service/white-label-center.service';
import { NavbarContent } from '../service/white-label-navbarr.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class WLHomeComponent {
  centers: Center[] = [];
  showNavBarData: NavbarContent;
  isMobileResolution = false;
  showFooter = true;

  constructor(
    private whiteLabelCenterService: WhiteLabelCenterService,
    private router: Router
  ) {
    const host = 'reservation.forest-hill.fr';
    this.whiteLabelCenterService
      .getCenterByDomain(host)
      .subscribe((centers) => {
        this.centers = centers;
        if (this.centers.length == 1) {
          this.router.navigate([`/club/${this.centers[0].id}/reservation`]);
        }
      });
  }
}
