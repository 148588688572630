import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SharedService } from '../marketplace/service/shared.service';
import { WhiteLabelCenterService } from './service/white-label-center.service';
import { WhiteLabelFooterService } from './service/white-label-footer.service';
import {
  NavbarContent,
  WhiteLabelNavbarService
} from './service/white-label-navbarr.service';
import { SeoService } from '../whitelabel/service/seo.service';
import { BreakpointService } from '../shared/services/breakpoint.service';

@Component({
  selector: 'app-whitelabel',
  templateUrl: './whitelabel.component.html',
  styleUrls: ['./whitelabel.component.css']
})
export class WhitelabelComponent implements OnInit {
  showNavBarData: NavbarContent;
  showFooter = true;
  isMobileResolution = false;

  constructor(
    private whiteLabelCenterService: WhiteLabelCenterService,
    private navBarService: WhiteLabelNavbarService,
    private footerService: WhiteLabelFooterService,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private seoService: SeoService,
    private breakpointService: BreakpointService
  ) {
    this.breakpointService.isMobile().subscribe((isMobile) => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution =
      this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe((isMobileResolution) => {
      this.isMobileResolution = isMobileResolution;
    });
  }

  ngOnInit(): void {
    this.navBarService.contentNavigationBarObservable.subscribe((value) => {
      this.showNavBarData = value;
    });
    this.footerService.showFooterObservable.subscribe((value) => {
      this.showFooter = value;
    });

    const host = 'reservation.forest-hill.fr';
    this.whiteLabelCenterService.getOrFetchWhiteLabelCenters(host).subscribe({
      next: (center) => {
        if (center && center.length > 0) {
          this.seoService.initSeo(center[0]);
          console.log('center', center[0]);
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
}
