import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AppContextService} from "../../shared/services/app-context.service";
import {BookingService} from "../service/booking.service";
import {CartInformationService} from "../service/cart-information.service";

@Component({
  selector: 'app-paiement-service',
  templateUrl: './paiement-service.component.html',
  styleUrls: ['./paiement-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaiementServiceComponent implements OnInit {
  centerId: string;
  serviceId: string;
  isWhiteLabel: boolean;
  matchJoinDataMaybe: any;
  duration: number;
  startDateTime: string;
  isLoading = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appContextService: AppContextService,
    private bookingService: BookingService,
    private cartInformationService: CartInformationService
  ) {
    this.centerId = this.route.snapshot.params.centerId;
    this.serviceId = this.route.snapshot.params.serviceId;
    this.duration =  Number(this.route.snapshot.queryParams.duration);
    this.startDateTime = this.route.snapshot.queryParams.startDateTime;
    this.isWhiteLabel = this.appContextService.getisWhiteLabel();
    this.matchJoinDataMaybe = JSON.parse(localStorage.getItem("join"));
  }

  ngOnInit(): void {
    this.bookingService.createCart(this.initCart()).subscribe((res) => {
      this.cartInformationService.cartData = res;
      this.isLoading = false;
      this.router.navigate([`/checkout/cart/${res.id}`]);
    }, (error) => {
      this.isLoading = false;
      this.router.navigate([`/`]);
      console.log(error);
    });
  }

  initCart(): any{
    if(this.matchJoinDataMaybe && this.matchJoinDataMaybe.join){
      return  {
        "type" : "JOIN_MATCH",
        "matchId": this.matchJoinDataMaybe.matchId
      }
    } else {
     return  {
        "type": "CUSTOMER_MATCH",
        "startTime": this.startDateTime,
        "duration": this.duration,
        "serviceId": this.route.snapshot.params.serviceId,
        "places": [],
      }
    }
  }

}
