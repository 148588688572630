import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { UserPreferenceService } from './user-preferences.service';
import {
  generateMetaTagMarketplace,
  tagToMetaDefinition
} from '../../marketplace/service/meta-content';
import { Center } from '../models/center';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private meta: Meta,
    private title: Title,
    private userPreferenceService: UserPreferenceService,
    @Inject(DOCUMENT) private dom
  ) {}

  initSeo(center?: Center): void {
    const tags = generateMetaTagMarketplace(center);
    this.setTitles(tags.title);
    const metaDefinitionTags = tags.tags.map((tag) => {
      return tagToMetaDefinition(tag);
    });
    this.meta.addTags(metaDefinitionTags);
    this.setLinks(tags.links);
  }

  setTitles(title: string): void {
    this.title.setTitle(title);
  }

  setLinks(linksInfo: any[]): void {
    linksInfo.forEach((link) => {
      this.setLink(link);
    });
  }

  setLink(linkInfo: any): void {
    const link: HTMLLinkElement = this.dom.createElement('link');
    linkInfo.rel ? link.setAttribute('rel', linkInfo.rel) : null;
    linkInfo.type ? link.setAttribute('type', linkInfo.type) : null;
    linkInfo.sizes ? link.setAttribute('sizes', linkInfo.sizes) : null;
    linkInfo.href ? link.setAttribute('href', linkInfo.href) : null;
    this.dom.head.appendChild(link);
  }

  generateTags(config): void {
    // default values
    config = {
      image: 'https://www.anybuddyapp.com/assets/images/anybuddy-logo.png',
      url: 'https://www.anybuddyapp.com',
      ...config
    };

    this.title.setTitle(config.title.replace('""', ''));
    this.meta.updateTag({ name: 'description', content: config.description });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({
      name: 'twitter:description',
      content: config.description
    });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({
      property: 'og:locale',
      content: this.userPreferenceService.getCountry()
    });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({
      property: 'og:description',
      content: config.description
    });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.createCanonicalURL();
  }

  createCanonicalURL(): void {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    let url = this.dom.URL.split('?')[0]
      .replace('http://', 'https://')
      .replace(
        'https://europe-west1-anybuddy-91142.cloudfunctions.net',
        'https://www.anybuddyapp.com'
      );
    if (url.startsWith('/')) {
      url = 'https://www.anybuddyapp.com' + url;
    }
    link.setAttribute('href', url);
  }
}
