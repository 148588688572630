import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { WhiteLabelFooterService } from '../../whitelabel/service/white-label-footer.service';
import {
  NavbarContent,
  WhiteLabelNavbarService
} from '../../whitelabel/service/white-label-navbarr.service';
import { Center } from '../models/center';
import { NewReservation } from '../models/new-reservation';
import {ServiceInformation} from '../models/serviceInformation';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { BookingService } from '../service/booking.service';
import { CentersService } from '../service/center.service';
import {
  InfoPaiementService, PriceService
} from '../service/shared.service';
import { TagManagerService } from '../service/tag-manager.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { AppContextService } from '../../shared/services/app-context.service';
import { Card } from './card.interface';
import { CardUpdateService } from '../service/card-update.service';
import {forkJoin, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {CartItem, CartResponse} from "../models/cart-response";
import {Prices} from "../models/prices";
import {Places} from "../models/places";

/* eslint-disable */
@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.scss'],
  providers: [PriceService]
})
export class PaiementComponent implements OnInit {
  @ViewChild('content') public content: ElementRef;
  @ViewChild('tarifContent') public tarifContent: ElementRef;
  @ViewChild('promoContent') public promoContent: ElementRef;
  @ViewChild('resaError') public resaError: ElementRef;
  @ViewChild('mymodal') public myModal: ElementRef;

  isWhiteLabel: boolean;
  public service;
  //public slotService: ServicesSlotsInformation;
  public activity: string;
  public startDate;
  public center: Center;

  public pricePromo = 'Ajouter un Code Promo';
  public centerId;
  public photoService;
  public serviceId;
  public closeResult: string;
  public newReservationModel: NewReservation;
  public confirmationCheck: boolean;
  public isCE: boolean = false;
  public error: boolean;
  public errorMessage: string;
  public errorServer: boolean;
  public loadVALUE: boolean = false;
  public partner: string;
  public imgPartner: string;
  public linkCGV: string;
  public importantMsg: string;
  public duration: number;
  public isLoading: boolean = false;
  cards: Card[] = [];
  userEmail: string = '';
  emailValidatorForm: any;
  emailVerified: boolean = false;
  emailSent: boolean = false;
  resendEmail: boolean = false;
  color: string = '#FFC300';
  servPrice: ServiceInformation;
  initial: ServiceInformation;
  paymentInClub: boolean = false;
  isCenterMember = false; // la serta rien
  user;
  services = [];
  //////////////////////////////////////////
  cartData: CartResponse;
  cartItem: CartItem;
  isConfigSimpleDouble: boolean = false;
  buttonSimpleDouble: string = 'simple';
  isPayOnlyMine  = false;
  singlePayerSupported = false;
  splitSupported = false;
  isMultiPrices = false;
  placeDispo = 0;
  walletAmount = 0;
  total = 0;
  fees = 0;
  amountToBePaid = 0;
  prices: Prices[] = [];
  originalPrices: Prices[] = [];
  originalUserPrices: Prices[] = [];
  userPrice: Prices[] = [];
  placesSelected: Places[] = [];
  bookedPlaces = 0;
  public matchIdStarted : boolean = false;
  matchId : string = "";
  public hasMatchId : boolean = false;
  payMissingShares: boolean = false;
  constructor(
    private modalService: NgbModal,
    private centersService: CentersService,
    private route: ActivatedRoute,
    private router: Router,
    private bookingService: BookingService,
    private _location: Location,
    private translate: TranslateService,
    private userPreferenceService: UserPreferenceService,
    public infoPaiementService: InfoPaiementService,
    private formBuilder: FormBuilder,
    private authFirebaseService: AuthFirebaseService,
    private tagManager: TagManagerService,
    private navBarService: WhiteLabelNavbarService,
    private footerService: WhiteLabelFooterService,
    private appContextService: AppContextService,
    private cardUpdateService: CardUpdateService // Injecter le service
  ) {
    if(!this.authFirebaseService.getIsLoggedIn){
      this.router.navigate(["/"])
    }

    const cartResponse = this.route.snapshot.data['cartResponse']
    if (cartResponse === undefined) {
      this.router.navigate(['/'])
    } else {
      this.cartData = cartResponse;
    }

    this.cartItem = this.cartData.items[0];
    this.placesSelected = this.cartItem.places ? this.cartItem.places : []
    this.centerId = this.cartItem.centerId;
    this.serviceId = this.cartItem.serviceId;
    this.isWhiteLabel = this.appContextService.getisWhiteLabel();
    this.startDate = this.cartItem.data.startDateTime;
    this.duration = this.cartItem.data.duration;
    this.activity = this.getItem('activities');

    this.buttonSimpleDouble = this.cartItem.data.nbPlayers <= 2 ? 'simple' : 'double';
    this.isPayOnlyMine = this.cartItem.data.paymentPlan === 'SPLIT';
    this.setData();
    this.loadCards();
    this.initUserInfos();
    this.cardUpdateService.cardUpdated$.subscribe(() => {
      this.loadCards(); // Recharger les cartes
    });
  }

  ngOnInit(): void {
    const start = new Date(this.startDate);
    const end = new Date(start.getTime() + this.duration * 60000);
    const endDateTime = end.toISOString().slice(0, 10) + 'T' + end.toTimeString().slice(0, 5);
    forkJoin([
      this.centersService.getCenter(this.centerId).pipe(
        catchError(error => {
          console.log('error center:', error);
          this.router.navigate(['/'])
          return of(null);
        })
      ),
      this.centersService.getCentersAvailabilities(
        this.centerId,
        this.activity,
        this.startDate,
        '',
        '',
        '',
        endDateTime,
        false
      ).pipe(
        catchError(error => {
          console.log('error center availabilities:', error);
          return of(null);
        })
      )
    ]).subscribe(([center, availabilities ]) => {
      this.center = center;
      const startDateTime = new Date(this.startDate).getTime();
      const availabilitiesFound = availabilities.data.filter(avail => {
        const date = new Date(avail.startDateTime).getTime();
        return date === startDateTime}
      )
      if(availabilitiesFound && availabilitiesFound.length > 0){
        this.originalPrices = availabilitiesFound[0].services.filter(service => service.id === this.serviceId && service.duration == this.duration).flatMap(service => service.prices)
        this.originalPrices = this.originalPrices.filter(price => price != null);
        this.originalUserPrices = availabilitiesFound[0].services.filter(service => service.id === this.serviceId && service.duration == this.duration).flatMap(service => service.userPrices)
        this.originalUserPrices = this.originalUserPrices.filter(price => price != null);
        this.updatePrices();
      } else {
        this.originalPrices = [];
        this.originalUserPrices = [];
        this.prices = [];
        this.userPrice = [];
      }
      this.setData();
      this.initCenterData();
      this.initNonPaimenData();
      this.updateInfos(null, null);
      this.pushBeginCheckoutTag();
      if(this.isMultiPrices && !(this.isPayOnlyMine && this.servPrice.userPrices)) {
        this.isTarifPopup(this.tarifContent)
      }
    });
  }

  updatePrices(){
    if(this.originalPrices){
      this.prices = structuredClone(this.originalPrices);
      this.prices.map(price => {
        if(price.isPricePerFacility){
          price.price = (price.priceComplete / (this.cartItem.data.nbPlayers ? this.cartItem.data.nbPlayers : price.nbPlaces));
          price.nbPlaces = 1;
        } else {
          price.price = (price.priceComplete / price.nbPlaces);
          price.nbPlaces = 1;
        }


      })

      this.setData();
    } else {
      this.prices = [];
    }

    if(this.originalUserPrices){
      this.userPrice = structuredClone(this.originalUserPrices);
      this.userPrice.map(price => {
        if(price.isPricePerFacility){
          price.price = (price.priceComplete / (this.cartItem.data.nbPlayers ? this.cartItem.data.nbPlayers : price.nbPlaces));
          price.nbPlaces = 1;
        } else {
          price.price = (price.priceComplete / price.nbPlaces);
          price.nbPlaces = 1;
        }
      })
      this.setData();
    } else {
      this.userPrice = []
    }


  }

  setData(): void {
    if(this.cartData){
      const place = this.isPayOnlyMine ? 1 : this.cartItem.data.nbPlayers ? this.cartItem.data.nbPlayers : 1;
      this.servPrice = {
        totalCapacity: place,
        //availablePlaces: place, //- (this.cartItem.places ? this.cartItem.places.length : 1),
        prices: [],
      }
      this.placeDispo = place;
      if(this.prices && this.prices.length > 1){
        this.isMultiPrices =  true;
        this.servPrice.prices = this.prices;
        this.servPrice.userPrices = this.userPrice;
        this.infoPaiementService.servPrice.places = this.cartItem.places ? this.cartItem.places : [];
        this.bookedPlaces = this.cartItem.places ? this.cartItem.places.map(p => p.nbPlace).reduce(function(a, b) {return a + b;}) : 0;
      }
    }
  }

  initNonPaimenData(): void {
    this.initNavbar()
    this.initCGVLink()
    this.pricePromo = this.translate.instant('voucher.promoCode');
    this.initEmailValidationForm();
  }

  initNavbar(): void {
    if (this.isWhiteLabel) {
      this.navBarService.sendShowNavBarData(new NavbarContent(false, '', true));
      this.footerService.footerInvisible();
    }
  }

  getItem(sessionStorageName: string): string {
    return sessionStorage.getItem(sessionStorageName)
  }

  initEmailValidationForm(): void {
    this.emailValidatorForm = this.formBuilder.group({
      email: [
        this.userEmail,
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        ]
      ]
    });
  }

  pushBeginCheckoutTag(): void {
    this.tagManager.pushBeginCheckout({
      center_id: this.centerId,
      activity_id: this.activity,
      price: this.total,
      date: this.startDate,
      duration: this.duration,
      user_id: this.authFirebaseService.getUserDataId()
    });
  }

  initCenterData(): void {
    this.services = this.center.services;
    this.service = this.center.services.find((val) => val['id'] === this.serviceId);
    this.importantMsg = this.center.importantMessage;
    if (this.center.bookingType === 'onsite') {
      this.paymentInClub = true;
    }
    if (this.service.photos.length > 0) {
      this.photoService = this.service.photos[0].small;
    } else {
      this.photoService = this.center.profilePhoto['192x192'];
    }
  }

  async payer(content, emailVerification) {
    if (!this.emailVerified) {
      await this.authFirebaseService.reloadUser();
      this.initUserInfos();
    }
    if (this.confirmationCheck) {
      if (this.error) {
        this.error = false;
      }
      if (!this.isWhiteLabel && !this.emailVerified) {
        this.emailSent = false;
        this.resendEmail = false;
        this.modalService.open(emailVerification, {
          size: 'lg',
          centered: true
        });
      } else {
        this.tagManager.pushPaymentInitiated({
          center_id: this.centerId,
          activity_id: this.activity,
          price: this.total,
          date: this.startDate,
          duration: this.duration,
          user_id: this.authFirebaseService.getUserDataId()
        });
        this.modalService
          .open(content, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            backdrop: 'static'
          })
          .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (_) => {
            this.closeResult = `Dismissed`;
          }
        );
      }
    } else {
      this.error = true;
    }
  }

  public applyVoucher(event) {
    const newVoucher = {
      "vouchers": event.promo
    }
    this.updateCart(newVoucher);
    this.modalService.dismissAll();
  }

  newPlacesSelected(places: Places[]){
    const update = {
      places: places,
    }
    this.updateCart(update);
    this.placesSelected = places;
  }

  closeEmailModal() {
    this.modalService.dismissAll();
  }

  public back(): void {
    this._location.historyGo(-2);
  }
  isTarifPopup(tarifContent): void {
    this.modalService.open(tarifContent, {
      scrollable: true,
      windowClass: 'modal-multiple-price'
    });
  }
  public openModal(event): void {
    this.partner = event.part;
    this.imgPartner = event.img;
    this.modalService.dismissAll();
    this.modalService.open(this.promoContent, { centered: true });
  }
  public openFeeInfoModal(feeInfo): void {
    this.modalService.open(feeInfo, {
      centered: true,
      windowClass: 'feemodal'
    });
  }
  public onCheckChange(event): void {
    this.confirmationCheck = event.target.checked;
    if(this.error && !this.isErrorServer() && this.confirmationCheck){
      this.error = false
    }
  }

  public openModalPromo(content): void {
    this.modalService.open(content, { centered: true });
  }

  get email() {
    return this.emailValidatorForm.get('email');
  }

  onSubmitEmailValidatorForm() {
    this.sendMail();
    this.emailSent = true;
    this.resendEmail = false;
  }

  sendMail() {
    return this.authFirebaseService._sendVerificationMail();
  }

  resendCodeEmail(): void {
    this.resendEmail = true;
    this.sendMail();
  }

  isErrorServer(): boolean {
    return this.errorServer;
  }

  initUserInfos() {
    this.user = this.authFirebaseService.getUser();
    this.userEmail = this.user.email;
    this.emailVerified = this.user.emailVerified;
  }

  isConfirmationCheck(): boolean {
    return this.confirmationCheck;
  }

  loadCards() {
    this.bookingService.getCustomerCards().subscribe({
      next: (result) => {
        this.cards = result;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  private initCGVLink() {
    if (this.userPreferenceService.getCountry() === 'es') {
      this.linkCGV = '/es/condiciones-generales/';
    } else {
      this.linkCGV = '/conditions-generales/';
    }
  }

  ////////////////////

  updateCart(body){
    this.isLoading = true;
    this.bookingService.updateCart(body, this.cartData.id, this.cartItem.id).subscribe({
      next: (res: CartResponse) => {
        this.cartData = res;
        this.updateInfos(res, body);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
      }
    });
  }

  updateInfos(cartResponse: CartResponse, body: any){
    this.cartItem = this.cartData.items[0];
    if(this.cartItem.data.matchType.includes(2) && this.cartItem.data.matchType.includes(4) && this.cartItem.data.matchType.length == 2)
      this.isConfigSimpleDouble = true;
    if(this.cartItem.data.supportedPaymentPlans.includes("SINGLE_PAYER"))
      this.singlePayerSupported = true;
    if(this.cartItem.data.supportedPaymentPlans.includes("SPLIT"))
      this.splitSupported = true;
    if(this.cartData.paymentDetails.wallet != undefined)
      this.walletAmount = this.convertStringToPrice(this.cartData.paymentDetails.wallet);
    this.total = this.convertStringToPrice(this.cartData.paymentDetails.priceFMT);
    this.fees = this.convertStringToPrice(this.cartData.paymentDetails.commissionFMT);
    this.amountToBePaid = this.convertStringToPrice(this.cartData.paymentDetails.amountToBePaidFMT);
    if(this.isMultiPrices){
      this.updatePrices();
    }
    this.payMissingShares = this.cartItem.completePaymentMissingShare
    this.placesSelected = this.cartItem.places ? this.cartItem.places : [];
    this.newReservationModel = new NewReservation(
      this.centerId,
      '',
      this.activity,
      this.serviceId,
      Math.ceil(this.total),
      this.center.currenciesAccepted,
      Math.ceil(this.total),
      null,//event.promo,
      this.placesSelected,
      this.fees
    );

  }

  checkoutCart(body){
    this.bookingService.checkoutCart(body, this.cartData.id).subscribe({
      next: (reservationIntent) => {
        console.log(reservationIntent)
        this.getMatchId();
      }
    });
  }

  checkout(){
    const dataBody = {
      "setupFutureUsage": false,
      "automaticPayment": true,
      "paymentMethodType": "all",
    }
    this.checkoutCart(dataBody);
  }

  getMatchId(){
    let counter = 0;
    let hasMatchId = false;
    const interval = setInterval(() => {
      this.matchIdStarted = true;
      if (counter < 30 && !hasMatchId) {
        this.bookingService.getCart(this.cartData.id).subscribe({
          next: (res) => {
            let matchId = res.items[0].data.matchId
            if(matchId != undefined){
              hasMatchId = true
              this.matchId = matchId;
            }
            if(this.matchId !== ""){
              this.router.navigate([`/match/${this.matchId}`]);
            }
          },
          error: (error) => {
            console.log(error);
          }
        });
        counter++;
      } else {

        clearInterval(interval);
      }
    }, 1000);
  }


  selectButtonSimpleDouble(button : string): void {
    if(button == "simple"){
      this.buttonSimpleDouble = "simple";
    }else if(button == "double"){
      this.buttonSimpleDouble = "double";
    }
    this.updateCartData();
  }

  updateCartData(){
    let numNbPlayers = 2;
    let paymentPlan = "SINGLE_PAYER"
    if(this.buttonSimpleDouble == 'double'){
      numNbPlayers = 4;
    }else if(this.buttonSimpleDouble == 'simple'){
      numNbPlayers = 2;
    }
    if(this.isPayOnlyMine){
      paymentPlan = "SPLIT";
    }else{
      paymentPlan = "SINGLE_PAYER";
    }
    if(!this.isConfigSimpleDouble){
      numNbPlayers = this.cartItem.data.nbPlayers ? this.cartItem.data.nbPlayers : this.cartItem.data.matchType[0];
    }
    const choiceSimpleDouble = {
      "paymentPlan": paymentPlan,
      "nbPlayers": numNbPlayers,
    }
    if(this.isPayOnlyMine){
      this.placeDispo = 1;
      this.servPrice.totalCapacity = 1;
      //this.servPrice.availablePlaces = 1;
    } else {
      if(this.buttonSimpleDouble == 'double'){
        this.placeDispo = 4;
        this.servPrice.totalCapacity = 4;
        //.servPrice.availablePlaces = 4;
      }else if(this.buttonSimpleDouble == 'simple'){
        this.placeDispo = 2;
        this.servPrice.totalCapacity = 2;
        //this.servPrice.availablePlaces = 2;
      } else {
        this.placeDispo = 1;
        this.servPrice.totalCapacity = 1;
        //this.servPrice.availablePlaces = 1;
      }
    }
    this.updateCart(choiceSimpleDouble);
  }
  handleClick(state : boolean){
    this.isPayOnlyMine = state;
    this.updateCartData();
  }

  convertStringToPrice(string : string){
    if(!isNaN(parseInt(string[string.length - 1]))){
      string = string.substring(0,string.length-1);
    }
    if(string.includes(",")){
      string = string.replace(/,/g, '.');
    }
    if(string.includes(" ")){
      string = string.replace(/ /g, '');
    }
    return parseFloat(string);
  }

  validateCart(){
    this.bookingService.validateCart(this.cartData.id).subscribe(
      value => {
        console.log(value)
      }
    )
  }

  onCheckChangePayMissingShares(event) {
    const newVoucher = {
      "completePaymentMissingShare": event.target.checked
    }
    this.updateCart(newVoucher);

  }
}
