<app-top-navbar
    *ngIf="!isMobileResolution"
    [isMobileResolution]="isMobileResolution"
    [country]="country">
    <div
        class="d-flex justify-content-between align-items-center ms-auto"
        app-top-navbar-right
        *ngIf="!isMobileResolution">
        <a [routerLink]="['/' + country, 'on-boarding']" class="no-link-underline">
            <button class="navbar-btn navbar-btn navbar-btn-yellow border-0 me-0 my-3">
                {{'menuComponent.add_your_center' | translate}}
            </button>
        </a>
        <app-button-account-disconnected></app-button-account-disconnected>
    </div>
</app-top-navbar>

<div class="container">
    <h1 class="title mt-5 mb-5">{{title}}</h1>
    <div [innerHTML]="rawContent"></div>
</div>

<div class="d-flex flex-column section-footer"
    [ngClass]="{'mt-5': isMobileResolution, 'mt-2 mb-3 mx-5 px-5 ': !isMobileResolution}">
    <app-footer [isMobileResolution]="isMobileResolution"></app-footer>
</div>
