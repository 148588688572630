import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Center } from '../../marketplace/models/center';
import { WhiteLabelCenterService } from '../service/white-label-center.service';

export const WhiteLabelCenterResolver: ResolveFn<Center[]> = () => {
  const whiteLabelCenterService: WhiteLabelCenterService = inject(
    WhiteLabelCenterService
  );
  return whiteLabelCenterService.getOrFetchWhiteLabelCenters(
    'reservation.forest-hill.fr'
  );
};
