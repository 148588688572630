<div *ngIf="isBrowser">
  <carousel *ngIf="!byFour" [arrowsOutside]="arrowOutside" [cellsToShow]="cardShown" class="carousel-custom">
    <div class="carousel-cell d-flex p-0" *ngFor="let town of townList" [style.background]="'url(' + town.background + ')'"  (click)="orientation(town)"> 
      <div class="calc d-flex">
        <div class="mt-auto text-container">
          <div class="title">{{town.name}}</div>
          <div class="sport">{{town.sports}}</div>
        </div>
      </div>
    </div>
  </carousel>

  <carousel *ngIf="byFour" [arrowsOutside]="arrowOutside" [cellsToShow]="cardShown" >
    <div class="carousel-cell d-flex row" *ngFor="let towns of townListByFour">
      <div class="col-6  p-1" *ngFor="let town of towns" >
        <div class="bg-cover h-100 d-flex " [style.background]="'url(' + town.background + ')'" (click)="orientation(town)"> 
          <div class="p-1 calc">
            <div class="mt-auto">
              <div class="text-container">
                <div class="title">{{town.name}}</div>
                <div class="sport">{{town.sports}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </carousel>
</div>

