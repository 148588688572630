<ng-container>
  <app-loader *ngIf="!hasMatchId && matchIdStarted"></app-loader>
</ng-container>



<div class="header-container">
  <div class="price-icon-container">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 9H4v7h16v-7zm0-4V5H4v3h16z" fill="rgba(255,255,255,1)" />
    </svg>
  </div>
  <h3 class="title">{{ 'stripe.add_card' | translate }}</h3>
</div>

<div *ngIf="center.importantMessage">
  <h3 class="important-message">{{ center.importantMessage }}</h3>
</div>

<div *ngIf="!selectNewCard">

  <div class="card-selected mt-2">
    {{ 'savecard.card_selected_paiment' | translate}}
  </div>

  <div class="mt-1">
    <div class="list-card">
      <div *ngFor="let card of cards; let i = index" class="row mx-0 p-0 mb-2">
        <div class="card custom-card mx-auto col-10" [ngClass]="{'custom-card-selected': selectedCard && selectedCard.id == card.id}">
          <div class="card-body card-content" (click)="selectCard(card)">
            <div class="row align-items-center">
              <div class="col-10 d-flex">
                <h5 class="card-title card-title-custom">{{ 'savecard.card_title' | translate:{selectedCard: card} }}</h5>
                <p class="card-text card-text-custom mb-0">{{ 'savecard.card_number' | translate:{selectedCard: card} }}</p>
              </div>
              <div class="col-2 text-end d-flex">
                <svg *ngIf="selectedCard && selectedCard.id == card.id" class="ms-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path></svg>
              </div>
            </div>
          </div>
        </div>

        <div class="col-2 d-flex">
          <button class="delete-card-button m-auto" (click)="askDeleteCard(card, mymodal)"><span aria-hidden="true align">&times;</span></button>
        </div>
      </div>
    </div>


    <div class="card custom-card mb-4 mt-2 col-10" [ngClass]="{'custom-card-selected': newcardselected}">
      <div class="card-body card-content" (click)="newcard()">
        <div class="row align-items-center">
          <div class="col-10">
            <h5 class="card-title card-title-custom">{{ 'savecard.new_card' | translate }}
            </h5>
          </div>
          <div class="col-2 text-end d-flex">
            <svg *ngIf="newcardselected" class="ms-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path></svg>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-check my-3" *ngIf="!selectedCard">
    <input class="form-check-input" type="checkbox" id="flexCheckChecked" [(ngModel)]="savedcheck" (change)="onCheckboxChange($event)">
    <label class="form-check-label" for="flexCheckChecked">{{ 'savecard.message_save' | translate }}</label>
  </div>

  <p *ngIf="isPaidSuccess == false" class="errorPayment">{{ errorPayment }}</p>

  <button id="submit" class="btn-submit" (click)="!selectedCard && newcardselected ? checkoutNewCard() : handleFormSubmission(true)" [disabled]="shouldDisableButton()">
    <svg *ngIf="isLoad" version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 -7 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" style="margin-top: -5px;">
    <path fill="#a778fb" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
      <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite" />
    </path>
  </svg>

    <span id="button-text" *ngIf="newcardselected">{{ 'savecard.fill_and_pay' | translate }}</span>
    <span id="button-text" *ngIf="!newcardselected">{{ 'checkout.valid_and_pay' | translate }}</span>
  </button>

  <div id="payment-message" class="hidden"></div>

</div>

<div *ngIf="selectNewCard" class="content">
  <h3 class="text-center">{{ 'savecard.add_card' | translate }}</h3>
  <form id="payment-form">
    <div class="form-row">
      <div class="input-block first d-flex">
        <input id="firstname" name="firstname" [placeholder]="'checkout.firstname' | translate" required class="input-custom w-45 me-auto" [ngClass]="errorName ? 'error-input' : ''" [(ngModel)]="userProfile.firstName" />
        <input id="name" name="name" [placeholder]="'checkout.lastname' | translate" required class="input-custom w-45 ms-auto" [ngClass]="errorName ? 'error-input' : ''" [(ngModel)]="userProfile.lastName" />
      </div>
      <input id="mail" type="email" name="mail" placeholder="Mail" required class="StripeElement" [(ngModel)]="userProfile.email" class="input-custom w-100" [ngClass]="errorMail ? 'error-input' : ''" />
      <p class="soustitle">{{ 'checkout.email_infos' | translate }}</p>
    </div>
    <div id="link-authentication-element"><!--Stripe.js injects the Link Authentication Element--></div>
    <div #paymentElement id="payment-element"><!--Stripe.js injects the Payment Element--></div>
    <div *ngIf="!loaded" class="d-flex mb-2">
      <svg class="mx-auto" version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 -7 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" style="margin-top: -5px;">
          <path fill="#a778fb" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
            <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite" />
          </path>
        </svg>
    </div>

    <p *ngIf="isPaidSuccess == false" class="errorPayment">{{ errorPayment }}</p>
    <button id="submit" class="btn-submit" (click)="handleFormSubmission()" [disabled]="shouldDisablePaiementButton()">
      <svg *ngIf="isLoad" version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 -7 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" style="margin-top: -5px;">
          <path fill="#a778fb" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
            <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite" />
          </path>
        </svg>
      <span id="button-text">{{ (showTextBook ?'checkout.booked' :'checkout.valid_and_pay') | translate }}</span>
    </button>
    <div id="payment-message" class="hidden"></div>
  </form>
</div>

<ng-template #mymodal let-modal>
  <div class="modal-body">
    <h3 class="title-cards mx-auto my-3">{{ 'savecard.delete_card' | translate }}</h3>

    <div class="d-flex">
      <div class="m-auto d-flex">
        <h5 class="card-title card-title-custom">{{ 'savecard.card_title' | translate:{selectedCard: cardToDelete} }}</h5>
        <p class="card-text card-text-custom mb-0">{{ 'savecard.card_number' | translate:{selectedCard: cardToDelete} }}</p>
      </div>
    </div>

    <div class="row d-flex mt-4">
      <div class="col-5 mx-auto">
        <button class="btn btn-danger" (click)="modal.close('Ok click')">oui</button>
      </div>
      <div class="col-5 mx-auto">
        <button class="btn btn-submit" (click)="modal.dismiss('Ok click')">non</button>
      </div>
    </div>

  </div>
</ng-template>
