import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export function isMarketplaceDomain(): boolean {
  return false;
}

export function isForestHillDomain(): boolean {
  return environment.host.includes('forest-hill.fr');
}

@Injectable({
  providedIn: 'root'
})
export class AppContextService {
  private _isWhiteLabel: boolean;

  constructor() {
    this._isWhiteLabel = !isMarketplaceDomain();
  }

  getisWhiteLabel(): boolean {
    return this._isWhiteLabel;
  }
}
