<!-- LOADER -->
<ng-container *ngIf="!isMobileResolution">
    <app-loader *ngIf="!mapReady"></app-loader>
</ng-container>

<!-- LOADER -->
<ng-container *ngIf="isMobileResolution">
    <app-loader *ngIf="!loading"></app-loader>
</ng-container>

<!-- MENU TOP -->

<div class="top-container" #top>
    <app-top-navbar *ngIf="!isMobileResolution" [isMobileResolution]="isMobileResolution" [country]="country">
        <app-menu-top app-top-navbar-left (isSport)="openSport($event)" [sportSelected]='sportSelected'
            [dateInfo]='date' [ville]='city' [charachterics]="charachterics" [price]="price"
            [servicetype]="servicetype">
        </app-menu-top>
        <div class="navbar-btn" app-top-navbar-right>
            <a [routerLink]="['/' + country, 'on-boarding']">
                <div class="btn-rounded my-3" routerLinkActive="active">
                    {{'menuComponent.add_your_center' | translate}}
                </div>
            </a>
            <app-button-account-disconnected></app-button-account-disconnected>
        </div>
    </app-top-navbar>
</div>

<app-menu-bottom *ngIf="isMobileResolution" [sport]="sport" [city]="city" (isFilter)="isFilter($event)"></app-menu-bottom>
<div *ngIf="loading && mapReady" class="container" #container>
    <div class="d-flex main-container-availabilities">
        <div class="center-container" id="top" (scroll)="onScroll($event)" #centerContainer>
            <div class="back-top-container" #backTopContainer>
                <button class="back-top" (click)="backToTop()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(199,199,199,1)"/></svg>
                </button>
            </div>
            <ng-container *ngIf="isMobileResolution">
                <nav class="navbar navbar-light justify-content-between mt-1 p-0">
                    <a [routerLink]="['/']">
                        <button class="custom-img p-0">
                            <img class="icon-nav" ngSrc="v1677166288/assets/arrow/arrow-left-line.png" width="36" height="36">
                        </button>
                    </a>
                </nav>
            </ng-container>

            <div class="d-flex flex-column mt-3">
                <div class="d-flex">
                    <img class="icon-localisation" ngSrc="v1677166288/assets/icon/map-pin-line-black.png" width="24" height="24">
                    <h1 *ngIf="infoSelected === 'sport'" class="title-list-club card-title " (click)="openCity()">
                        {{ 'availabilities.header_no_sport' | translate }}
                        <span class="city">{{town | titlecase}}</span>
                    </h1>
                    <h1 *ngIf="infoSelected !== 'sport'" class="title-list-club card-title " (click)="openCity()">
                        {{ 'availabilities.header_sport' | translate: {sport: infoSelected} }}
                        <span class="city">{{town | titlecase}}</span>
                    </h1>
                </div>
                <ng-container *ngIf="isMobileResolution">
                    <div class="d-flex">
                        <img class="icon-calendar" ngSrc="v1677166288/assets/icon/calendar-2-line.png" width="24" height="24">
                        <span>
                            <a (click)="open(mymodal)" class="card-subtitle underline">{{ dateDisplay | titlecase }} -
                                {{heureActuel}}</a>
                        </span>
                    </div>
                </ng-container>
            </div>

            <nav class="mt-5 breadcrumb-container" aria-label="breadcrumb custom-breadcrumb">
                <ol class="breadcrumb breadcrumb-ol grey-text p-0">
                    <li class="breadcrumb-item"><a href="#" class="breadcrumb-link grey-text ">{{ 'appComponent.home' |
                            translate }}</a></li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/' + country, 'reservation-sport', formatCity]">
                            <span class="breadcrumb-link grey-text" style="cursor: pointer;">{{ town |
                                titlecase}}</span>
                        </a>
                    </li>
                    <li *ngIf="infoSelected !== 'sport'" class="breadcrumb-item"><span
                            class="breadcrumb-link grey-text ">{{ infoSelected | titlecase }}</span></li>
                    <li *ngIf="infoSelected === 'sport'" class="breadcrumb-item"><span
                            class="breadcrumb-link grey-text ">{{ 'center_availabilities.sport' | translate }}</span>
                    </li>
                </ol>

            </nav>

            <h2 class="mb-2 horizontal-line"></h2>
            <div class="selectInfo" *ngIf="hasSport">
                <button *ngIf="isScrollableSport && !isMobileResolution" class="btn" (click)="onPrev('sportSlider')">
                    <img ngSrc="v1677166288/assets/arrow/arrow-left.png" alt="arrow left" class="arrow" width="96" height="96">
                </button>
                <div class="sport-container" #sportSlider>
                    <ng-container *ngFor="let sportinfo of listActivity.activities">
                            <div *ngIf="isMobileResolution">
                                <div [ngClass]="sportSelected === sportinfo.id ? 'sport-mobile-selected' : 'sport-mobile'"
                                    *ngIf="sportinfo.id !== 'beach-tennis'  && sportinfo.id !== 'table-tennis'  && sportinfo.id !== 'racquetball' && getImageUrl(sportinfo)!==''"
                                    (click)="orientation(sportinfo.id)">
                                    <p>{{sportinfo.name}}</p>
                                </div>
                            </div>
                            <div *ngIf="!isMobileResolution">
                                <div [ngClass]="sportSelected === sportinfo.id ? 'sport-selected' : 'sport'"
                                    *ngIf="sportinfo.id !== 'beach-tennis'  && sportinfo.id !== 'table-tennis'  && sportinfo.id !== 'racquetball' && getImageUrl(sportinfo)!==''"
                                    (click)="orientation(sportinfo.id)">
                                    <img class="sport-image" [src]="getImageUrl(sportinfo)" alt={{sportinfo.id}} width="80" height="83">
                                    <p>{{sportinfo.name}}</p>
                                </div>
                            </div>
                    </ng-container>
                </div>
                <button *ngIf="isScrollableSport && !isMobileResolution" class="btn" (click)="onNext('sportSlider')">
                    <img ngSrc="v1677166288/assets/arrow/arrow-right.png" alt="arrow right" class="arrow" width="96" height="96">
                </button>
            </div>


          <div class="disp-inherit mt-2">
            <div class="buttonDiv">
              <button *ngIf="sportSelected && hasSport" [attr.data-notifications]="numberFilters >0 ? numberFilters: null" class="buttonFilter"
                      (click)="openFilterModal(filterContent)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2h10.17zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4h4.17zM9 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>
                <div class="select-filter">
                  {{ 'center_availabilities.refine_search' | translate }}
                </div>
              </button>
            </div>


            <div class="select-types" *ngIf="sportTypeList && sportTypeList.length > 1">
              <button *ngIf="isScrollableTypeSport && !isMobileResolution" class="btn" (click)="onPrev('typeSportSlider')">
                <img ngSrc="v1677166288/assets/arrow/arrow-left.png" alt="arrow left" class="arrow">
              </button>
              <div class="type-container" #typeSportSlider>
                <div class="service-type" [value]="'all'" (click)="chooseSessionsType($event.target)" [ngClass]="serviceTypeIsSelected('all') ? 'service-selected':''">
                  {{ "availabilities.all_sport_type" | translate: {sport: sportSelected} }}
                </div>
                <ng-container *ngFor="let type of sportTypeList">
                  <div class="service-type" [value]="type.id" (click)="chooseSessionsType($event.target)" [ngClass]="serviceTypeIsSelected(type.id) ? 'service-selected':''">
                    {{type.name}}
                  </div>
                </ng-container>
              </div>
              <button *ngIf="isScrollableTypeSport && !isMobileResolution" class="btn" (click)="onNext('typeSportSlider')">
                <img ngSrc="v1677166288/assets/arrow/arrow-right.png" alt="arrow right" class="arrow" width="96" height="96">
              </button>
            </div>


            <div class="select-types" *ngIf="!sportTypeList && hasInfrasctructures && infrastructures.length > 1">
              <button *ngIf="isScrollableTypeSport && !isMobileResolution" class="btn" (click)="onPrev('typeSportSlider')">
                <img ngSrc="v1677166288/assets/arrow/arrow-left.png" alt="arrow left" class="arrow" width="96" height="96">
              </button>
              <div class="type-container" #typeSportSlider>
                <!--
                <div class="service-type" [value]="'all'" (click)="chooseSessionsType($event.target)" [ngClass]="infraIsSelected('all') ? 'service-selected':''">
                  {{ "availabilities.all_infra_type" | translate: {sport: sportSelected} }}
                </div>
                -->
                <ng-container *ngFor="let type of infrastructures">
                  <div class="service-type" [value]="type.id" (click)="chooseInfra(type)" [ngClass]="infraIsSelected(type.id) ? 'service-selected':''">
                    {{type.name}}
                  </div>
                </ng-container>
              </div>
              <button *ngIf="isScrollableTypeSport && !isMobileResolution" class="btn" (click)="onNext('typeSportSlider')">
                <img ngSrc="v1677166288/assets/arrow/arrow-right.png" alt="arrow right" class="arrow" width="96" height="96">
              </button>
            </div>

          </div>




            <div *ngIf="!hasSport" class="poupsport-container">
              <div class="w-100 select-sport-list">
                <h4>{{ 'center_availabilities.select_sport' | translate }}</h4>
              </div>
              <ng-container *ngFor="let sportinfo of activies">
                <div class="Poupsport" *ngIf="sportinfo.id !== 'beach-tennis'  && sportinfo.id !== 'table-tennis'  && sportinfo.id !== 'racquetball' && getImageUrl(sportinfo)!==''">
                  <img (click)="orientation(sportinfo.id)"
                       class="imgSelect" [src]="getImageUrl(sportinfo)">
                  <div (click)="orientation(sportinfo.id)"
                       class="caption-sport-title-home" [ngClass]="infoSelected === sportinfo.id ? 'bold' : 'regular'">
                    {{sportinfo.name}}
                  </div>
                </div>
              </ng-container>
              <div class="mt-3 d-flex buddy">
                <div class="img">
                  <img ngSrc="v1677226694/assets/buddy/buddy_tennis.svg" width="247" height="247" alt="buddy badminton">
                </div>
                <div class="text">{{ 'center_availabilities.see_disponibility' | translate }}</div>
              </div>
            </div>
            <h2 *ngIf="textHeader" class="mb-2 horizontal-line"></h2>
            <div class="header-text" *ngIf="textHeader">
                <p [innerHtml]="textHeader"></p>
            </div>
            <div class="container-card" >
                <ng-container *ngIf='sportSelected !== "fitness" && sportSelected !== "tennis"; then cardContainer; else fitnessContainer'></ng-container>
                <ng-template ngbNavContent #cardContainer>
                    <div *ngIf="hasSport && !hasData()" class="noResults">
                        {{ 'center_availabilities.no_results' | translate }}
                    </div>
                    <div *ngIf="hasData() && activeSession !== 2">
                        <div class="card-list">

                            <div *ngFor="let club of dataList" class="card card-container">
                                <div class="card-main">
                                    <div class="card-img-container">
                                        <a [routerLink]="['/' + club.getPath()]">
                                            <img class="card-img card-img-top" src="{{ club.headerPhoto['280x140'] }}" width="280" height="140" alt="Card image cap">
                                        </a>
                                        <div class="favorite-container" (click)="setFavorite(club.id)">
                                            <svg *ngIf="!favoriteIndexedObj[club.id]" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 115.11 114" width="32" height="32"><defs><style>.cls-1 {fill: #fff;}.cls-2 {fill: #c7c7c7;opacity: .3;}.cls-3 {fill: none;}</style></defs><g id="Calque_2" data-name="Calque 2">
                                                <path class="cls-3" d="M0,0H115.11V114H0V0Z" />
                                                <path class="cls-2"d="M58.32,21.51c11.09-10.02,28.22-9.69,38.91,1.08,10.68,10.77,11.05,27.93,1.11,39.12l-40.03,40.34L18.29,61.72c-9.93-11.19-9.56-28.38,1.11-39.12,10.69-10.75,27.79-11.11,38.91-1.08Z" /></g><g id="Calque_1" data-name="Calque 1">
                                                <path class="cls-1"d="M57.93,21.3c11.32-10.16,28.81-9.82,39.71,1.1,10.9,10.93,11.28,28.33,1.14,39.69l-40.86,40.92L17.07,62.08c-10.14-11.35-9.76-28.79,1.14-39.69,10.91-10.91,28.37-11.28,39.72-1.1Zm32.89,7.91c-7.23-7.24-18.89-7.53-26.45-.74l-6.43,5.77-6.44-5.77c-7.59-6.8-19.23-6.5-26.47,.74-7.18,7.18-7.54,18.67-.93,26.27l33.83,33.88,33.83-33.87c6.62-7.6,6.26-19.08-.93-26.28Z" /></g>
                                            </svg>
                                            <svg *ngIf="favoriteIndexedObj[club.id]" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 115.11 114" width="32" height="32">
                                                <path fill="none" d="M0,0H115.11V114H0V0Z" />
                                                <path fill="rgba(255,97,97,1)" d="M58.32,21.51c11.09-10.02,28.22-9.69,38.91,1.08,10.68,10.77,11.05,27.93,1.11,39.12l-40.03,40.34L18.29,61.72c-9.93-11.19-9.56-28.38,1.11-39.12,10.69-10.75,27.79-11.11,38.91-1.08Z" />
                                                <path fill="#fff" d="M57.93,21.3c11.32-10.16,28.81-9.82,39.71,1.1,10.9,10.93,11.28,28.33,1.14,39.69l-40.86,40.92L17.07,62.08c-10.14-11.35-9.76-28.79,1.14-39.69,10.91-10.91,28.37-11.28,39.72-1.1Zm32.89,7.91c-7.23-7.24-18.89-7.53-26.45-.74l-6.43,5.77-6.44-5.77c-7.59-6.8-19.23-6.5-26.47,.74-7.18,7.18-7.54,18.67-.93,26.27l33.83,33.88,33.83-33.87c6.62-7.6,6.26-19.08-.93-26.28Z" />
                                            </svg>
                                        </div>
                                        <div class="rating-container">
                                            <app-star-rating [rating]="club.rating" [ratingCount]="club.ratingCount"
                                                [white]="true" [small]="true">
                                            </app-star-rating>
                                        </div>
                                        <div *ngIf="club.isFullAnybuddy && isMobileResolution" class="full-anybuddy">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                                <circle fill="#ffc300" cx="150" cy="150" r="101"/><circle fill="#ffc300" cx="198.29" cy="101.94" r="54.71"/><circle fill="#ffc300" cx="217.29" cy="155.94" r="54.71"/><circle fill="#ffc300" cx="198.29" cy="195.94" r="54.71"/><circle fill="#ffc300" cx="150.29" cy="216.94" r="54.71"/><circle fill="#ffc300" cx="103.29" cy="197.94" r="54.71"/><circle fill="#ffc300" cx="81.29" cy="156.94" r="54.71"/><circle fill="#ffc300" cx="95.29" cy="101.94" r="54.71"/><circle fill="#ffc300" cx="146.29" cy="78.94" r="54.71"/><path fill="#fff" d="M137.61,177.54l62.2-86.26,13.28,9.57-71.78,99.53-59.71-43.07,9.57-13.27,46.45,33.5Z"/>
                                            </svg>
                                        </div>
                                        <div class="distance-container">
                                            {{ club.distance / 1000 }} km
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between card-body-main">
                                            <div class="card-body-container d-flex flex-column">
                                                <div class="title-card-container">
                                                    <a [routerLink]="['/' + club.getPath()]" class="title-card-sub-container">
                                                        <h3 class="title-card">{{ club.name }} </h3>
                                                    </a>
                                                    <div *ngIf="club.isFullAnybuddy && !isMobileResolution" class="full-anybuddy"
                                                    placement="top"
                                                    ngbTooltip='{{ "center_availabilities.isFullAnybuddy" | translate }}'
                                                    tooltipClass="custom-tooltip">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                                            <circle fill="#ffc300" cx="150" cy="150" r="101"/><circle fill="#ffc300" cx="198.29" cy="101.94" r="54.71"/><circle fill="#ffc300" cx="217.29" cy="155.94" r="54.71"/><circle fill="#ffc300" cx="198.29" cy="195.94" r="54.71"/><circle fill="#ffc300" cx="150.29" cy="216.94" r="54.71"/><circle fill="#ffc300" cx="103.29" cy="197.94" r="54.71"/><circle fill="#ffc300" cx="81.29" cy="156.94" r="54.71"/><circle fill="#ffc300" cx="95.29" cy="101.94" r="54.71"/><circle fill="#ffc300" cx="146.29" cy="78.94" r="54.71"/><path fill="#fff" d="M137.61,177.54l62.2-86.26,13.28,9.57-71.78,99.53-59.71-43.07,9.57-13.27,46.45,33.5Z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <a [routerLink]="['/' + club.getPath()]">
                                                    <p class="address-card">{{ club.formattedAddress }} {{ club.neighborhoodName
                                                        }} {{ club.address.postalCode }}</p>
                                                </a>
                                                <ng-container *ngIf="!isMobileResolution">
                                                    <h2 class="mb-2 horizontal-line-card "></h2>
                                                </ng-container>
                                            </div>
                                            <div class="btn-availabilities-container">
                                                <a [routerLink]="['/' + club.getPath(), 'reservation', sportSelected]"
                                                    [queryParams]="{activity: sportSelected, date: today }" (click)="tagCenter(club)">
                                                    <button
                                                        class="button-rounded btn-show-avaibilities">
                                                        <p class="">
                                                            {{'center_availabilities.see_availabilities' | translate }}
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                                width="16" height="16">
                                                                <path fill="none" d="M0 0h24v24H0z" />
                                                                <path
                                                                    d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                                                                    fill="rgba(255,255,255,1)" />
                                                            </svg>
                                                        </p>
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="card-body-footer">
                                            <div class="card-body-description">
                                                <p class="card-text">{{ club.shortDescription}} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="club.timeSlots.length !== 0">
                                    <app-availabilities
                                        *ngIf="infoSportCarrosel==='tennis' || infoSportCarrosel==='padel' ||  infoSportCarrosel==='squash' || infoSportCarrosel==='badminton' "
                                        [currency]='club.currency' [centerService]='club.services'
                                        [sportSelected]='infoSportCarrosel' [center]="club" [slots]="club.timeSlots"
                                        (newDateEvent)="tagCenter(club, $event)">
                                    </app-availabilities>
                                </ng-container>
                            </div>

                        <div class="d-flex justify-content-center" *ngIf="isPagination">
                            <div class="spinner-border spinner" [ngStyle]="{'color':'#ffc300'}" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>

                        </div>
                    </div>
                </ng-template>
                <ng-template #fitnessContainer>
                    <nav ngbNav #nav="ngbNav" [(activeId)]="activeSession" class="nav-tabs">
                        <ng-container [ngbNavItem]="1">
                            <a  *ngIf="sportSelected === 'fitness'" class="nav-title" ngbNavLink (click)="isSession=false">{{ 'center_availabilities.club' | translate }}</a>
                            <a  *ngIf="sportSelected === 'tennis'" class="nav-title" ngbNavLink (click)="isSession=false" (click)="setTennisServiceType('tennis-court-rental')">Terrains</a>
                            <ng-template ngbNavContent>
                                <ng-container *ngIf='sportSelected === "fitness" || sportSelected === "tennis"; then cardContainer'></ng-container>
                            </ng-template>
                        </ng-container>
                        <ng-container [ngbNavItem]="2">
                            <a *ngIf="sportSelected === 'fitness' && sessionListSport.length !== 0" class="nav-title" ngbNavLink (click)="isSession=true">{{ 'center_availabilities.session' | translate }}</a>
                            <a *ngIf="sportSelected === 'tennis' && sessionListSport.length !== 0" class="nav-title" ngbNavLink (click)="isSession=true" (click)="setTennisServiceType('tennis-lessons')">Cours</a>
                            <ng-template ngbNavContent>
                                <div class="container-card">
                                    <div *ngIf="sessionListSport && sessionListSport.length === 0" class="noResults">
                                        {{ 'center_availabilities.no_results' | translate }}
                                    </div>
                                    <ng-container *ngIf="activeSession !== 1">
                                        <div class="d-flex justify-content-center" *ngIf="isPagination">
                                            <div class="spinner-border spinner" [ngStyle]="{'color':'#ffc300'}" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                        </div>
                                        <div class="card-list" *ngIf="!isPagination">
                                            <div *ngFor="let session of sessionListSport" class="card card-container">
                                                <div class="card-main">
                                                    <div class="card-img-container">
                                                        <a [routerLink]="['/' + session.center.getPath()]">
                                                            <img *ngIf='session.serviceCenter.photos.length !== 0' class="card-img card-img-top" src="{{session.serviceCenter.photos[0].small}}"
                                                            alt="Card image cap" width="280" height="140">
                                                            <img *ngIf='session.serviceCenter.photos.length === 0' class="card-img card-img-top" src="{{session.center.headerPhoto['420x210']}}"
                                                            alt="Card image cap" width="420" height="210">
                                                        </a>
                                                        <div class="distance-container">
                                                            {{ session.center?.distance / 1000 }} km
                                                        </div>
                                                    </div>
                                                    <div class="card-body session">
                                                        <div class="d-flex justify-content-between card-body-main">
                                                            <div class="card-body-container d-flex flex-column">
                                                                <div class="title-card-container">
                                                                    <a [routerLink]="['/' + session.center.getPath()]" class="title-card-sub-container">
                                                                        <h3 class="title-session-card">{{ session.center.name }} </h3>
                                                                    </a>
                                                                    <div *ngIf="session.center.isFullAnybuddy && !isMobileResolution" class="full-anybuddy"
                                                                    placement="top"
                                                                    ngbTooltip='{{ "center_availabilities.isFullAnybuddy" | translate }}'
                                                                    tooltipClass="custom-tooltip">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                                                            <circle fill="#ffc300" cx="150" cy="150" r="101"/><circle fill="#ffc300" cx="198.29" cy="101.94" r="54.71"/><circle fill="#ffc300" cx="217.29" cy="155.94" r="54.71"/><circle fill="#ffc300" cx="198.29" cy="195.94" r="54.71"/><circle fill="#ffc300" cx="150.29" cy="216.94" r="54.71"/><circle fill="#ffc300" cx="103.29" cy="197.94" r="54.71"/><circle fill="#ffc300" cx="81.29" cy="156.94" r="54.71"/><circle fill="#ffc300" cx="95.29" cy="101.94" r="54.71"/><circle fill="#ffc300" cx="146.29" cy="78.94" r="54.71"/><path fill="#fff" d="M137.61,177.54l62.2-86.26,13.28,9.57-71.78,99.53-59.71-43.07,9.57-13.27,46.45,33.5Z"/>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <a [routerLink]="['/' + session.center.getPath()]">
                                                                    <p class="address-card">{{ session.center.address.postalCode }} {{ session.center.address.city }}</p>
                                                                </a>
                                                                <ng-container *ngIf="!isMobileResolution">
                                                                    <h2 class="mb-2 horizontal-line-card "></h2>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                        <div class="card-body-session-footer">
                                                            <div class="card-body-session-description">
                                                                <p class="card-place">{{session.serviceCenter.name}}</p>
                                                                {{session.startDateTime | date: 'dd MMM yyyy - HH:mm' }}
    <!--
        ---------- Règle multi-prix ----------
        Si la variable prices est supérieure à 0 et que la variable availablePlaces l'est aussi, alors affiche "voir les tarifs"
        et redirige l'user sur la page de multi-prix
        ---------- Cas d'utilisation (pourquoi cette logique ?) ----------
        Si il n'y a qu'un tarif dans la variable prices mais 2 places dispos alors on redirige vers multi-prix
        Pareil dans le cas inverse, 2 tarifs mais qu'une place.
        ---------- Solution pour le back ----------
        Si il n'y a qu'une place dispo et qu'un price alors ne pas renvoyer la variable prices.
    -->
                                                                <div *ngIf="session.availablePlaces">
                                                                    {{session.availablePlaces}} {{ 'center_availabilities.places_available' | translate }}
                                                                </div>
                                                                <button (click)="openSessionDescriptionModal(session.serviceCenter.description)" *ngIf="session.serviceCenter.description && session.serviceCenter.description !== ''" class="btn-session-description">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" fill="rgba(255,255,255,1)"/></svg>
                                                                    <p>{{ 'center_availabilities.know_more' | translate }}</p>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" fill="rgba(255,255,255,1)"/></svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </ng-container>
                    </nav>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </ng-template>
            </div>
            <div *ngIf="!isPagination && (pagination && pagination.hasNextPage)" class="btn-pagination-container">
                <button (click)="addPagination()" class="btn-pagination">
                    {{ 'availabilities.pagination_show_more' | translate }}
                </button>
            </div>
            <div class="textFooter" [innerHtml]="textFooter"></div>
        </div>
        <ng-container *ngIf="!isMobileResolution">
            <div class="map-container">
                <app-map *ngIf="isBrowser" [displayMenu]="false" [charachterics]="charachterics" [radius]="radius"
                    [price]="price" [servicetype]="servicetype" [dataList]="mapDatalist" (isMapLoaded)="isMapReady($event)" (mapInit)="searchOnMap()" [hasSport]="this.hasSport" (cityChange)="this.city = $event"></app-map>
            </div>
        </ng-container>
    </div>

    <div class="d-flex flex-column mt-2 mb-3" *ngIf='!isDeca'>
        <div [ngClass]="{'px-3': !isMobileResolution }">
            <h2 class="horizontal-line"></h2>
            <div class="mt-4 mb-4" *ngIf="frLanguage">
                <p class="assistance-txt">{{ 'assistance.assistance' | translate }}</p>
                <ul class="assistance-ul">
                    <li>
                        <img class="question-mark" ngSrc="v1677166288/assets/icon/question-mark.png" alt="question mark" width="32" height="32">
                        <a class="custom-link" href="https://support.anybuddyapp.com/fr/" target="_blank">{{
                            'assistance.help' | translate }}</a>
                    </li>

                    <li>
                        <img class="question-mark" ngSrc="v1677166288/assets/icon/question-mark.png" alt="question mark" width="32" height="32">
                        <a class="custom-link" target="_blank"
                            href="https://support.anybuddyapp.com/fr/article/comment-ca-fonctionne-qkcjfc/">{{
                            'assistance.how_anybuddy_works' | translate }}</a>
                    </li>
                </ul>
            </div>
            <h2 class="horizontal-line"></h2>
        </div>
        <div class="d-flex flex-column section-footer"
            [ngClass]="{'mt-5': isMobileResolution, 'mt-2 mb-3 mx-5 px-5 ': !isMobileResolution}">
            <app-footer [isMobileResolution]="isMobileResolution"></app-footer>
        </div>
        <h2 class="mb-2 horizontal-line mt-5 mb-4"></h2>
    </div>
</div>


<!-- MODAL -->
<ng-template #mymodal let-modal>
    <div class="modal-header">
        <button type="button" style="
        background: white;
        border: aliceblue;
    " aria-label="Close" (click)="modal.dismiss('Cross click')">
    <img ngSrc="v1677166288/assets/arrow/arrow-left-line.png" class="icon-nav" width="36" height="36" alt="arrow left">
  </button>
    </div>
    <div class="modal-body">
        <app-center-calendar (newDateEvent)="getDate($event)"></app-center-calendar>
    </div>
</ng-template>

<ng-template #filterContent let-modal>
    <div class="modal-header">
        <div class="filtre">{{ 'filters.filters' | translate}}</div>
        <button type="button" style="
      background: white;
      border: aliceblue;
  " aria-label="Close" (click)="modal.dismiss('Cross click')"> <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" />
            </svg></button>
    </div>
    <div class="modal-body">
        <app-filter (submitFilter)="submitFilter($event)" (closeFilterModal)="modal.dismiss('Cross click')"
            [filtercharacteristic]="filtercharacteristic" [radius]="radius" [price]="price" [sport]="infoSelected" [servicetype]="servicetype" [sportTypeSelected]="sportTypeSelected" [isPartner]="isPartner">
        </app-filter>
    </div>
</ng-template>

<ng-template #Sport let-modal>
    <div class="modal-header">
        <div class="filtre"> {{'search.sport' | translate}}</div>
        <button type="button" style="
        background: white;
        border: aliceblue;
    " aria-label="Close" (click)="modal.dismiss('Cross click')"> <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" />
            </svg></button>
    </div>
    <div class="modal-body filter-sport-body">

        <ng-container *ngFor="let sportinfo of listActivity.activities">
            <div class="Poupsport" *ngIf="sportinfo.id !== 'beach-tennis'  && sportinfo.id !== 'table-tennis'  && sportinfo.id !== 'racquetball' && getImageUrl(sportinfo)!==''">
                <img (click)="orientation(sportinfo.id)"
                    class="imgSelect" [src]="getImageUrl(sportinfo)">
                <div (click)="orientation(sportinfo.id)"
                    class="caption-sport-title-home" [ngClass]="infoSelected === sportinfo.id ? 'bold' : 'regular'">
                    {{sportinfo.name}}
                </div>
            </div>
        </ng-container>
    </div>

</ng-template>

<ng-template #cityModal let-modal>
    <div class="modal-body">
        <app-search [isMobileResolution]="isMobileResolution" [avaibility]="avaibility"
            (avaibiltyCity)="getNewCity($event)" (avaibiltyCityDismiss)="closePoup($event)"></app-search>
    </div>

</ng-template>
