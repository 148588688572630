import {
  CommonModule,
  NgOptimizedImage,
  provideCloudinaryLoader
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { GoogleMapsModule } from '@angular/google-maps';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountComponent } from './marketplace/account/account.component';
import { CenterAvailabilityComponent } from './marketplace/center-availability/center-availability.component';
import { CenterCalendarComponent } from './marketplace/center-calendar/center-calendar.component';
import { CenterComponent } from './marketplace/center/center.component';
import { FooterComponent } from './marketplace/footer/footer.component';
import { HomeComponent } from './marketplace/home/home.component';
import { LanguageComponent } from './marketplace/language/language.component';
import { LoaderComponent } from './marketplace/loader/loader.component';
import { LoginComponent } from './shared/login/login.component';
import { LogoutComponent } from './shared/logout/logout.component';
import { MapComponent } from './marketplace/map/map.component';
import { MenuBottomComponent } from './marketplace/menu-bottom/menu-bottom.component';
import { MenuTopComponent } from './marketplace/menu-top/menu-top.component';
import { MenuComponent } from './marketplace/menu/menu.component';
import { MyBookingsComponent } from './marketplace/my-bookings/my-bookings.component';
import { ExternalCodeComponent } from './marketplace/paiement/external-code/external-code.component';
import { PromoCodeComponent } from './marketplace/paiement/promo-code/promo-code.component';
import { PresseCarouselComponent } from './marketplace/presse-carousel/presse-carousel.component';
import { SignupComponent } from './shared/signup/signup.component';
import { SportsCarouselComponent } from './marketplace/sports-carousel/sports-carousel.component';
import { ResetPasswordComponent } from './whitelabel/reset-password/reset-password.component';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFrBeExtra from '@angular/common/locales/extra/fr-BE';
import localeNlBeExtra from '@angular/common/locales/extra/nl-BE';
import localeFr from '@angular/common/locales/fr';
import localeFrBe from '@angular/common/locales/fr-BE';
import localeNlBe from '@angular/common/locales/nl-BE';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import {
  ScreenTrackingService,
  UserTrackingService
} from '@angular/fire/compat/analytics';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { environment } from '../environments/environment';
import { AuthGuardService } from './auth-guard.service';
import { AuthService } from './shared/services/auth.service';
import { FirebaseErrorGesture } from './firebase-error-gesture';
import { AuthInterceptor } from './http-auth-interceptor';
import { AllsessionformComponent } from './marketplace/allsessionform/allsessionform.component';
import { AssistanceComponent } from './marketplace/assistance/assistance.component';
import { AvailabilitiesComponent } from './marketplace/availabilities/availabilities.component';
import { BlogCarouselComponent } from './marketplace/blog-carousel/blog-carousel.component';
import { BlogListComponent } from './marketplace/blog-list/blog-list.component';
import { BlogComponent } from './marketplace/blog/blog.component';
import { ButtonAccountDisconnectedComponent } from './marketplace/button-account-disconnected/button-account-disconnected.component';
import { ButtonAccountComponent } from './marketplace/button-account/button-account.component';
import { ActivityDetailsComponent } from './marketplace/center-components/activity-details/activity-details.component';
import { AmenitiesComponent } from './marketplace/center-components/amenities/amenities.component';
import { CalendarCarouselComponent } from './marketplace/center-components/calendar-carousel/calendar-carousel.component';
import { CenterRecommendationsComponent } from './marketplace/center-components/center-recommendations/center-recommendations.component';
import { CenterReviewsComponent } from './marketplace/center-components/center-reviews/center-reviews.component';
import { LocalisationComponent } from './marketplace/center-components/localisation/localisation.component';
import { MultiplePricesComponent } from './marketplace/center-components/multiple-prices/multiple-prices.component';
import { ActivityAvailabilityDisplayerComponent } from './marketplace/center-components/select-activity-form/activity-availability-displayer/activity-availability-displayer.component';
import { ActivityAvailabilityFilterComponent } from './marketplace/center-components/select-activity-form/activity-availability-filter/activity-availability-filter.component';
import { SelectActivityFormComponent } from './marketplace/center-components/select-activity-form/select-activity-form.component';
import { CenterListComponent } from './marketplace/center-list/center-list.component';
import { InfosClubNoPartnerComponent } from './marketplace/center/components/infos-club-no-partner/infos-club-no-partner.component';
import { CompleteAccountComponent } from './marketplace/complete-account/complete-account.component';
import { FeedbackComponent } from './marketplace/feedback/feedback.component';
import { FilterComponent } from './marketplace/filter/filter.component';
import { FireBaseActionComponent } from './marketplace/fire-base-action/fire-base-action.component';
import { ForgotpwComponent } from './marketplace/forgotpw/forgotpw.component';
import { JoinResaComponent } from './marketplace/join-resa/join-resa.component';
import { NgxJsonLdComponent } from './marketplace/jsonId/jsonld.component';
import { MoreDetailsComponent } from './marketplace/more-details/more-details.component';
import { MultipleStarsRatingComponent } from './marketplace/multiple-stars-rating/multiple-stars-rating.component';
import { MyFavoriteComponent } from './marketplace/my-favorite/my-favorite.component';
import { MyPreferencesComponent } from './marketplace/my-preferences/my-preferences.component';
import { OnBoardingComponent } from './marketplace/on-boarding/on-boarding.component';
import { PageComponent } from './marketplace/page/page.component';
import { PaiementComponent } from './marketplace/paiement/paiement.component';
import { StripeComponentComponent } from './marketplace/paiement/stripe-component/stripe-component.component';
import { ReservationComponent } from './marketplace/reservation/reservation.component';
import {MatchComponent} from './marketplace/match/match.component';
import { ResetpwformComponent } from './marketplace/resetpwform/resetpwform.component';
import { SearchComponent } from './marketplace/search/search.component';
import { ActivityService } from './marketplace/service/activity.service';
import { AuthFirebaseService } from './marketplace/service/auth-firebase.service';
import { FavoriteService } from './marketplace/service/favorite.service';
import {
  CenterAvailabilitiesService,
  InfoPaiementService,
  SelectedSportService,
  SharedService,
  SlotService,
  StateService
} from './marketplace/service/shared.service';
import { UserPreferenceService } from './marketplace/service/user-preferences.service';
import { VisitorsService } from './marketplace/service/visitors.service';
import { SportsHomeCarouselComponent } from './marketplace/sports-home-carousel/sports-home-carousel.component';
import { StarBarRatingComponent } from './marketplace/star-bar-rating/star-bar-rating.component';
import { StarRatingComponent } from './marketplace/star-rating/star-rating.component';
import { SuccesResaComponent } from './marketplace/succes-resa/succes-resa.component';
import { TopNavBarComponent } from './marketplace/top-navbar/top-navbar.component';
import { TownCarouselComponent } from './marketplace/town-carousel/town-carousel.component';

import { ActivityDetComponent } from './whitelabel/activity-det/activity-det.component';
import { FooterWhiteLabComponent } from './whitelabel/footerwhitelab/footer-white-lab.component';
import { HeaderComponent } from './whitelabel/header/header.component';
import { CenterPageComponent } from './whitelabel/center-page/center-page.component';
import { LangChoiceComponent } from './whitelabel/lang-choice/lang-choice.component';
import { WhitelabelComponent } from './whitelabel/whitelabel.component';

import { DatetimeService } from './marketplace/service/datetime.service';
import { MyWalletsComponent } from './marketplace/my-wallets/my-wallets.component';
import { CommunityComponent } from './marketplace/community/community.component';
import { CommunityPageComponent } from './marketplace/community-page/community-page.component';
import { WLHomeComponent } from './whitelabel/home/home.component';
import { MyProfileComponent } from './marketplace/my-profile/my-profile.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import {PaiementServiceComponent} from "./marketplace/paiement-service/paiement-service.component";
import {WhiteLabelLoginComponent} from "./whitelabelforesthill/login/white-label-login.component";
import {RegisterFormComponent} from "./whitelabelforesthill/register/register-form/register-form.component";

registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeFrBe, 'fr-BE', localeFrBeExtra);
registerLocaleData(localeNlBe, 'nl-BE', localeNlBeExtra);
registerLocaleData(localeEs, 'es', localeEsExtra);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    '.json?cb=' + new Date().getTime()
  );
}

@NgModule({
    declarations: [
        CenterPageComponent,
        ResetPasswordComponent,
        ActivityDetComponent,
        FooterWhiteLabComponent,
        LangChoiceComponent,
        HeaderComponent,
        AppComponent,
        HomeComponent,
        LanguageComponent,
        PresseCarouselComponent,
        SportsCarouselComponent,
        MenuComponent,
        FooterComponent,
        LoginComponent,
        SignupComponent,
        AccountComponent,
        MyBookingsComponent,
        LogoutComponent,
        PaiementComponent,
        PromoCodeComponent,
        ExternalCodeComponent,
        CenterComponent,
        StarRatingComponent,
        SelectActivityFormComponent,
        CalendarCarouselComponent,
        CenterReviewsComponent,
        CenterRecommendationsComponent,
        ActivityDetailsComponent,
        AssistanceComponent,
        AmenitiesComponent,
        LocalisationComponent,
        MultipleStarsRatingComponent,
        CenterAvailabilityComponent,
        FilterComponent,
        CenterCalendarComponent,
        MenuBottomComponent,
        MenuTopComponent,
        MapComponent,
        NgxJsonLdComponent,
        LoaderComponent,
        FeedbackComponent,
        AllsessionformComponent,
        SearchComponent,
        AvailabilitiesComponent,
        StripeComponentComponent,
        SuccesResaComponent,
        CenterListComponent,
        MoreDetailsComponent,
        ForgotpwComponent,
        ResetpwformComponent,
        OnBoardingComponent,
        MultiplePricesComponent,
        StarBarRatingComponent,
        ReservationComponent,
        MatchComponent,
        MyFavoriteComponent,
        TopNavBarComponent,
        ButtonAccountComponent,
        InfosClubNoPartnerComponent,
        MyPreferencesComponent,
        ActivityAvailabilityFilterComponent,
        ActivityAvailabilityDisplayerComponent,
        TownCarouselComponent,
        SportsHomeCarouselComponent,
        CompleteAccountComponent,
        FireBaseActionComponent,
        JoinResaComponent,
        PageComponent,
        BlogComponent,
        BlogListComponent,
        BlogCarouselComponent,
        ButtonAccountDisconnectedComponent,
        WhiteLabelLoginComponent,
        WhitelabelComponent,
        RegisterFormComponent,
        MyWalletsComponent,
        CommunityComponent,
        CommunityPageComponent,
        WLHomeComponent,
        MyProfileComponent,
        PaiementServiceComponent
    ],
    imports: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        BrowserModule.withServerTransition({appId: 'websitebooking'}),
        TransferHttpCacheModule,
        HammerModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        NgbModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        NgxBootstrapSliderModule,
        IvyCarouselModule,
        NgOptimizedImage,
        ClipboardModule
    ],
    providers: [
        AuthService,
        AuthFirebaseService,
        AuthGuardService,
        provideCloudinaryLoader('https://res.cloudinary.com/anybuddy/'),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {provide: LOCALE_ID, useValue: 'fr'},
        ActivityService,
        SharedService,
        SelectedSportService,
        SlotService,
        VisitorsService,
        CenterAvailabilitiesService,
        UserPreferenceService,
        DatetimeService,
        StateService,
        InfoPaiementService,
        FavoriteService,
        {provide: ErrorHandler, useClass: FirebaseErrorGesture},
        UserTrackingService,
        ScreenTrackingService,
        {provide: FIREBASE_OPTIONS, useValue: environment.firebase},
        {provide: 'googleTagManagerId', useValue: environment.gtmId}
    ],
    exports: [
        LoaderComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
