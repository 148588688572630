<ng-container>
  <app-loader *ngIf="!dataAvailable"></app-loader>
</ng-container>

<div class="container" (window:resize)="widthSize($event)" *ngIf="dataAvailable && !resaSuccess">
  <header>
    <div class="header-svg">
      <svg [routerLink]="['/account']" routerLinkActive="active" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/></svg>
    </div>
  </header>

  <div class="title">
    <h1>{{ 'my_resa.your_resa' | translate }}</h1>
    <hr>
  </div>

  <div class="content">
    <div class="alert alert-danger">
      {{ 'my_resa.error' | translate }}
    </div>
  </div>
</div>

<div class="container" (window:resize)="widthSize($event)"  *ngIf="dataAvailable && resaSuccess">
  <header>
    <div class="header-svg">
      <svg [routerLink]="['/account']" routerLinkActive="active" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/></svg>
    </div>
  </header>

  <div class="title">
    <h1>{{ 'my_resa.your_resa' | translate }}</h1>
    <hr>
  </div>

  <div class="content">
    <div class="left-container">
      <!-- Informations terrains -->
      <div class="informations-container">
        <h2>{{center.name }}</h2>
        <p>{{center.formattedAddress}}</p>

        <h2>{{displayName}}</h2>
        <div class="facilities">
          <p *ngIf="facility.facilitySurface !=='unknown'">{{facility.facilitySurface}}</p>
          <p *ngIf="facility.facilitySurface !=='unknown'"> - </p>
          <p *ngIf="facility.isIndoor">{{ 'my_resa.indoor' | translate }}</p>
          <p *ngIf="!facility.isIndoor">{{ 'my_resa.outdoor' | translate }}</p>
          <p *ngIf="facility.light"> - </p>
          <p *ngIf="facility.light">{{ 'my_resa.light' | translate }}</p>
        </div>
      </div>
      <!-- Fin Informations terrains -->

      <!-- Résa date -->
      <div class="resa-date-container">
        <p class="date">{{reservation.start | date:'EEEE dd MMM yyyy'}}</p>
        <p class="hours">{{reservation.start | date:'HH'}}H{{reservation.start | date:'mm'}} - {{reservation.end | date:'HH'}}H{{reservation.end | date:'mm'}}</p>
      </div>
      <!-- Fin Résa date -->

      <!-- Résa validée ou annulée -->
      <div *ngIf="reservation.status === status.Confirm" class="resa-valid">
        <p>{{ 'my_bookings.reservation_confirmed' | translate }}</p>
        <svg class="valid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="rgba(255,255,255,1)"/></svg>
      </div>
      <div *ngIf="reservation.status === status.Cancel || reservation.status === status.Fail" class="resa-valid">
        <p *ngIf="reservation.status === status.Cancel">{{ 'my_bookings.reservation_cancelled' | translate }}</p>
        <p *ngIf="reservation.status === status.Fail">{{ 'my_bookings.reservation_fail' | translate }}</p>
        <svg class="denied" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" fill="rgba(255,255,255,1)"/></svg>
      </div>
      <div *ngIf="reservation.status === status.Waiting" class="resa-valid">
        <p>{{ 'my_bookings.reservation_waiting' | translate }}</p>
        <svg class="valid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z" fill="rgba(255,255,255,1)"/></svg>
      </div>
      <!-- Fin Résa validée ou annulée -->


      <!-- Récapitulatif de la réservation mobile -->
      <div class="recap-resa-container" *ngIf="isLittleWidth">
        <hr>
        <h1>{{ 'my_resa.resume_resa' | translate }}</h1>
        <!-- Si pas de choix de place -->
        <!-- Si pas de choix de place -->
        <div class="resa-info" *ngIf="!places.length">
          <div class="resa">
            <p class="site">{{ 'my_resa.site' | translate }} {{reservation.activityName}}</p>
          </div>
          <p class="price">{{ price / 100 | currency:'EUR':'symbol':'1.2-2':'fr' }}</p>
        </div>

        <!-- Places jeunes et adultes achetés -->
        <div class="resa-info" *ngIf="places.length">
          <div class="resa">
            <p class="resa-title">{{ 'my_resa.players' | translate | titlecase}}</p>
            <div class="price-info" *ngFor="let place of places">
              <p class="players">{{ place.name }} <strong>x {{place.nbPlace}}</strong> </p>
              <p class="price">{{ place.price / 100 | currency:"EUR"}}</p>
            </div>
          </div>
        </div>

        <div class="promo-container" *ngIf="vouchers.length">
          <p class="price-title">{{ 'my_resa.promo_code' | translate }}</p>
          <div  *ngFor="let voucher of vouchers">
            <div class="promo" *ngIf="voucher.discountAmount > 0">
              <p class="promo-name">{{voucher.name}}</p>
              <p class="price">- {{ voucher.discountAmount / 100 | currency:'EUR':'symbol':'1.2-2':'fr' }}</p>
            </div>
          </div>
        </div>

        <div class="fee-container" *ngIf="reservation.serviceFee !== undefined && reservation.serviceFee !== null">
          <div class="fee-content">
            <div class="fee-label">
              <div class="fee-space" *ngIf="reservation.serviceFee > 0" (click)="openFeeInfoModal(feeInfo)">
                <p class="title-fee">{{ 'my_resa.serviceFee' | translate }}</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"/></svg>
              </div>
              <div class="fee-space" *ngIf="reservation.serviceFee===0 && reservation.serviceFeeText !== null && reservation.serviceFeeText !== ''">
                <p class="title-fee" style="cursor: auto">{{ reservation.serviceFeeText }}</p>
              </div>
            </div>
            <div class="fee-price" *ngIf="reservation.serviceFee > 0">
              <p class="price">+ {{reservation.serviceFee / 100 | currency:"EUR"}}</p>
            </div>
          </div>
        </div>

        <div class="total-container">
          <p class="price-title">{{ 'my_resa.total_price' | translate }}</p>
          <p class="price">{{ reservation.priceCentsTotal / 100 | currency:'EUR':'symbol':'1.2-2':'fr' }}</p>
        </div>
        <div *ngIf="canShowDownloadInvoice()">
          <button class="btn-download" (click)="downloadInvoice()">{{'my_resa.downloadInvoice' | translate}}</button>
        </div>
      </div>
      <!-- Fin Récapitulatif de la réservation -->

      <hr *ngIf="reservation.importantMsg && reservation.importantMsg !== null">

      <div class="important-info-container mt-3" *ngIf="reservation.importantMsg && reservation.importantMsg !== null">
        <div class="title">
          <h1>{{'my_resa.important_msg' | translate}}</h1>
        </div>
        <div class="content">
          <p class="text">
            <span class="subtitle">{{reservation.importantMsg.title}} :</span>
            {{reservation.importantMsg.content}}
          </p>
        </div>
      </div>


      <!-- Instructions -->
      <div *ngIf="reservation.instructions.length !== 0 && reservation.importantMessage" class="instructions-container">
        <hr>
        <h2>{{ 'my_resa.directions_title' | translate }}</h2>
        <p>{{reservation.importantMessage}}</p>
      </div>
      <!-- Fin Instructions -->
      <hr>

      <!-- Section Partager la réservation-->
      <div class="share-container">
        <h2>{{ 'my_resa.share' |
          translate }}</h2>
        <div class="btn-partage">
          <span>{{ 'my_resa.share_reservation' |
            translate }}</span>
        </div>
      </div>
      <!-- Section Partager la réservation-->

      <hr>

      <!-- Annulation -->
      <div class="instructions-container">
        <h2>{{ 'my_resa.cancellation_title' | translate }}</h2>
        <p class="texte-cancel">{{'my_resa.resa_cancel_is' | translate}}
          <span *ngIf="!reservation.isCancellable">
            {{ 'my_resa.not_cancellable' | translate }}
          </span>
          <span *ngIf="reservation.isCancellable && reservation.isCancellableNow">
            {{ 'my_resa.cancellable' | translate }}
          </span>
          <span *ngIf="reservation.isCancellable && !reservation.isCancellableNow">
            {{ 'my_resa.cancellable_anymore' | translate }}
          </span>
        </p>
        <p *ngIf="!reservation.isCancellable" class="m-0">{{reservation.cancellationCondition}}</p>
        <div class="text-center" *ngIf="reservation.isCancellable && reservation.isCancellableNow">
        <button class="btn-cancel"
                [ngClass]="{'btn-cancelable': reservation.isCancellable && reservation.isCancellableNow }"
                (click)="isError = false; isCancel = false; openCancelReservation(confirmModal)">{{'my_resa.cancel_resa' | translate}}</button>
        </div>
      </div>
      <!-- Fin Annulation -->

      <hr>

      <!-- Localisation google map -->
      <div class="localisation-container">
        <h2>{{ 'my_resa.location_title' | translate }}</h2>
        <p>{{center.name}}</p>
        <p>{{center.formattedAddress}}</p>
      </div>
      <!-- google map -->
      <app-localisation [center]="center" [googleMapLink]="googleMapLink" [hideTitle]="hideTitle"></app-localisation>
      <!-- Fin Localisation google map -->

    </div>

    <div class="right-container" *ngIf="!isLittleWidth">
      <!-- Récapitulatif de la réservation mobile -->
      <div class="recap-resa-container">
        <h1>{{ 'my_resa.resume_resa' | translate }}</h1>

        <!-- Si pas de choix de place -->
        <div class="resa-info" *ngIf="!places.length">
          <div class="resa">
            <p class="site">{{ 'my_resa.site' | translate }} {{reservation.activityName}}</p>
          </div>
          <p class="price">{{ price / 100 | currency:"EUR"}}</p>
        </div>

        <!-- Places jeunes et adultes achetés -->
        <div class="resa-info" *ngIf="places.length">
          <div class="resa">
            <p class="resa-title">{{ 'my_resa.players' | translate | titlecase}}</p>
            <div class="price-info" *ngFor="let place of places">
              <p class="players">{{ place.name }} x{{place.nbPlace}}</p>
              <p class="price">{{ place.price / 100 | currency:"EUR"}}</p>
            </div>
          </div>
        </div>

        <div class="promo-container" *ngIf="vouchers.length">
          <p class="price-title">{{ 'my_resa.promo_code' | translate }}</p>
          <div class="promo" *ngFor="let voucher of vouchers">
            <p *ngIf="voucher.discountAmount > 0" class="promo-name">{{voucher.name}}</p>
            <p *ngIf="voucher.discountAmount > 0" class="price">- {{ voucher.discountAmount / 100 | currency:'EUR':'symbol':'1.2-2':'fr' }}</p>
          </div>
        </div>

        <div class="fee-container" *ngIf="reservation.serviceFee !== undefined && reservation.serviceFee !== null">
          <div class="fee-content">
            <div class="fee-label">
              <div class="fee-space" *ngIf="reservation.serviceFee > 0" (click)="openFeeInfoModal(feeInfo)">
                <p class="title-fee">{{ 'my_resa.serviceFee' | translate }}</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"/></svg>
              </div>
            </div>
            <div class="fee-price" *ngIf="reservation.serviceFee > 0">
              <p class="price">+ {{reservation.serviceFee / 100 | currency:"EUR"}}</p>
            </div>
          </div>
        </div>

        <hr>

        <div class="total-container">
          <p class="price-title">{{ 'my_resa.total_price' | translate }}</p>
          <p class="price">{{ reservation.priceCentsTotal / 100 | currency:'EUR':'symbol':'1.2-2':'fr' }}</p>
        </div>
        <div *ngIf="canShowDownloadInvoice()">
          <button class="btn-download" (click)="downloadInvoice()">{{'my_resa.downloadInvoice' | translate}}</button>
        </div>
      </div>
      <!-- Fin Récapitulatif de la réservation -->
      <div class="important-info-container mt-3" *ngIf="reservation.importantMsg && reservation.importantMsg !== null ">
        <div class="title">
          <h1>Important</h1>
        </div>
        <div class="content">
          <p class="text">
            <span class="subtitle">{{reservation.importantMsg.title}} :</span>
            {{reservation.importantMsg.content}}
          </p>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #feeInfo let-modal style="height: auto">
  <div class="modal-header">
    <div class="col" style="display: flex">
      <h2>{{ 'my_resa.serviceFee' | translate }}</h2>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col">
      <p class="fee-modal-text">{{reservation.serviceFeeText}}</p>
    </div>
  </div>
</ng-template>

<ng-template #cancelResa let-modal style="height: auto">
  <div class="modal-header">
    <div class="col" style="display: flex">
      <h2>{{ 'my_resa.serviceFee' | translate }}</h2>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body">
      <div class="col">
        <p class="fee-modal-text">{{reservation.serviceFeeText}}</p>
      </div>
      <div>
        <button>Non</button>
        <button>Oui</button>
      </div>
    </div>
  </div>
</ng-template>



<ng-template #confirmModal let-modal>
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="container">
      <ngb-alert *ngIf="isCancel" type="success">{{ 'my_bookings.success_resa_cancel' | translate }}</ngb-alert>
      <div class="title">
        <h4>{{ 'my_bookings.confirm_cancel_reservation' | translate }}</h4>
      </div>
      <div class="btn-confirm">
        <button class="button button-denied" (click)="modal.dismiss('Cross click')"> {{ 'my_bookings.no' |
          translate }} </button>
        <button class="button button-confirm" (click)="cancelReservation(modal)"> {{
          'my_bookings.yes' | translate }} </button>
      </div>
      <div *ngIf="isError" class="error-cancel">
        <p>{{notCancelableError}}</p>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #errorInvoice let-modal style="height: auto">
  <div class="modal-header">
    <div class="col" style="display: flex">
      <h2>{{ 'my_resa.invoice' | translate }}</h2>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body">
      <div class="col w-100">
        <p>{{ 'my_resa.errorInvoice' | translate}}</p>
        <p class="text-center break-word error">{{invoiceErrorMsg}}</p>
      </div>
    </div>
  </div>
</ng-template>
