import { formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import {
  ActivatedRoute,
  NavigationExtras,
  ParamMap,
  Router
} from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { Center } from '../../models/center';
import { Chracteristics } from '../../models/characteristic';
import { Service } from '../../models/service';
import { ServiceInformation } from '../../models/serviceInformation';
import { ServicesSlots } from '../../models/slots';
import { CentersService } from '../../service/center.service';
import {
  PriceService,
  SharedService,
  SlotService
} from '../../service/shared.service';

import { Prices } from '../../models/prices';

import { Places } from '../../models/places';
import {
  ActivityAvailabilityFilterComponent,
  FilterContent
} from './activity-availability-filter/activity-availability-filter.component';
import { BreakpointService } from '../../../shared/services/breakpoint.service';

/* eslint-disable */
@Component({
  selector: 'app-select-activity-form',
  templateUrl: './select-activity-form.component.html',
  styleUrls: ['./select-activity-form.component.scss'],
  providers: [PriceService]
})
export class SelectActivityFormComponent implements OnInit {
  isMobileResolution = true;
  @Input() activities: any;
  @Input() isWhiteLabel: boolean;
  @Input() centerId: string;
  @Input() center: Center;
  @Input() sportAvaibility: string;
  @Input() slotDate: string;
  @Output() newSportSelectedEvent = new EventEmitter<string>();
  @ViewChild('content') content: ElementRef;
  @ViewChild('tarifcontent', { static: false }) public tarifcontent: ElementRef;
  displayAvailabilities = false;
  error: any;
  selectedDate: string;
  selectedSlot: ServicesSlots[];
  loading: boolean;
  servicesAvailable: Service[];

  servPrice: ServiceInformation;
  charachteristic: Chracteristics[] = [];
  prices: Prices[] = [];
  places: Places[] = [];

  characteristic: Chracteristics[] = [];
  serviceInof: ServiceInformation;
  serviceInformation: ServiceInformation[] = [];
  color: string = '#FFC300';
  resaForm: UntypedFormGroup;
  selectedSport: any;
  typeSelected: boolean;
  sportSelected: boolean;
  partySize = 0;
  feePrice = 0;

  nbrPlaces = 0;
  selectedType: string;
  types: any[];
  filters: any[];
  sportName: boolean = false;
  servicesSlots: ServicesSlots[];
  serviceTypeFiltre: any[];
  MoreDetailsComponent: any;
  SelectActivityFormComponent: any;
  destroy = new Subject<any>();
  modalRef = null;
  selectedListing: any;
  isChangeNbrFromPaiement: boolean;
  initial: ServiceInformation;
  j: any;

  slotId: string;
  nbplacerule: number;
  listofid: number[];
  placePrise = 0;
  placeDispo = 0;
  urlPath: string;
  endDate: string;
  tennisDefaultChoice = 'tennis-court-rental';
  filter: FilterContent;
  fitnessSports = [
    'aquagym',
    'cardio',
    'danse',
    'muscle-strengthening',
    'relaxation-well-being',
    'team-training',
    'fitness'
  ];

  @ViewChild(ActivityAvailabilityFilterComponent)
  filterComponent: ActivityAvailabilityFilterComponent;

  /* eslint-disable */
  constructor(
    private sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    private slotService: SlotService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private centersService: CentersService,
    private localize: LocalizeRouterService,
    public modalService: NgbModal,
    private activateRoute: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private breakpointService: BreakpointService
  ) {
    this.breakpointService.isMobile().subscribe((isMobile) => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution =
      this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe((isMobileResolution) => {
      this.isMobileResolution = isMobileResolution;
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    const regexResa = /^\/club\/\d+\/reservation$/; // Adjust the regex according to your route pattern
    if(regexResa.test(this.router.url) && this.activities && this.activities.length > 0){
      this.newSportSelectedEvent.emit(this.activities[0].id)
      this.router.navigate([this.router.url+'/'+this.activities[0].id]);
    }
    this.getParamsUrl();
    let translatedPath: any = this.localize.translateRoute(`booking`);
    this.urlPath = this.center.getPath() + '/' + translatedPath;
    this.isChangeNbrFromPaiement = false;
    this.resaForm = this.fb.group({
      sport: this.selectedSport,
      resaType: '',
      filterChoices: new UntypedFormArray([]),
      partySize: 0
    });
    if (
      (this.activities.length === 1 && this.activities[0].id === 'tennis') ||
      this.selectedSport === 'tennis'
    ) {
      this.fetchActivityTypes(this.centerId, 'tennis');
    }
    this.updateAvailabilities(true);
  }

  getParamsUrl(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (this.activateRoute.snapshot.queryParamMap.get('activity')) {
        this.newSportSelected(
          this.activateRoute.snapshot.queryParamMap.get('activity')
        );
      }
      if (params.get('sport')) {
        if (this.selectedSport == params.get('sport')) {
          return;
        }
        if (this.activateRoute.snapshot.queryParamMap.get('date')) {
          this.slotDate = this.activateRoute.snapshot.queryParamMap.get('date');
          this.selectedDate = this.slotDate;
        }
        this.newSportSelected(params.get('sport'));
      }
      if (this.selectedSport !== '') {
        this.sportSelected = true;
      }
    });
  }

  resetParam(): void {
    this.serviceInformation = [];
    this.displayAvailabilities = false;
    this.sportSelected = false;
    this.typeSelected = false;
    this.selectedType = '';
    this.servicesAvailable = [];
    this.displayAvailabilities = false;
    this.selectedSlot = null;
    this.servicesSlots = [];
    this.types = [];
    this.filters = [];
    this.resaForm = this.fb.group({
      sport: this.selectedSport,
      resaType: '',
      filterChoices: new UntypedFormArray([]),
      partySize: 0
    });
  }

  newSportSelected(sport: string): void {
    if (this.selectedSport == sport) {
      return;
    }
    if (this.filter) {
      this.filter = null;
      this.filterComponent.resetFilterSportACtivity();
    }
    this.sportName = true;
    this.resetParam();
    if (sport !== '') {
      if (this.selectedType !== '') {
        this.typeSelected = true;
      }
      this.selectedSport = sport;
      this.newSportSelectedEvent.emit(sport);
      this.updateAvailabilities();
    }
  }

  fetchActivityTypes(centerid, sport): void {
    this.loading = true;
    //brancher au moment d'api filter sera merger
    this.centersService.getFilter(centerid, sport).subscribe({
      next: (res) => {
        this.types = res;
        if (this.types.length === 1) {
          this.onSelectDefautType();
        }
        if (
          (this.activities.length === 1 &&
            this.activities[0].id === 'tennis') ||
          this.selectedSport === 'tennis'
        ) {
          if (!this.selectedSport) {
            this.selectedSport = 'tennis';
          }
          this.onResaTypeChange('0', this.tennisDefaultChoice);
        }
        this.sportSelected = true;
        this.loading = false;
        this.typeSelected = true;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.sportSelected = false;
        this.loading = false;
      }
    });
  }

  onSelectDefautType(): void {
    if (this.resaForm) {
      this.resaForm.controls.filterChoices = new UntypedFormArray([]);
    }
    this.selectedType = this.types[0]['name'];
    this.filters = this.types[0]['resourceCharacteristicTypes'];
    let arrFiltre = this.types[0]['resourceCharacteristicTypes'].concat(
      this.types[0]['serviceCharacteristicTypes']
    );
    let foo = new Map();
    for (const tag of arrFiltre) {
      foo.set(tag.id, tag);
    }
    let final = [...foo.values()];
    this.filters = final;
    if (this.resaForm) {
      this.resaForm.controls.resaType.setValue(this.types[0].id);
    }
    this.fetchActivityFilters(this.types[0].id);
  }

  onResaTypeChange(id, nameActivity): void {
    this.resaForm.controls.filterChoices = new UntypedFormArray([]);
    this.selectedType = nameActivity;
    this.filters = this.types[id]['resourceCharacteristicTypes'];
    let arrFiltre = this.types[id]['resourceCharacteristicTypes'].concat(
      this.types[id]['serviceCharacteristicTypes']
    );
    let foo = new Map();
    for (const tag of arrFiltre) {
      foo.set(tag.id, tag);
    }
    let final = [...foo.values()];
    this.filters = final;
    this.updateAvailabilities();
    this.fetchActivityFilters(this.types[id].id);
  }

  // appelé quand le type est choisi
  fetchActivityFilters(type): void {
    this.loading = true;
    this.typeSelected = true;
    this.loading = false;
  }

  onDateSelected(newItem: string): void {
    const dates = newItem.split('|');
    newItem = dates[0];
    this.endDate = dates[1];
    if (newItem !== this.selectedDate) {
      this.displayAvailabilities = false;
      this.loading = true;
      this.selectedDate = newItem;
      this.slotDate = newItem;
      this.updateDateQueryParams(this.selectedDate);
      this.updateAvailabilities();
    }
  }

  loadSlot(newItem: string): void {
    const dates = newItem.split('|');
    newItem = dates[0];
    this.endDate = dates[1];
    this.displayAvailabilities = false;
    this.loading = true;
    this.selectedDate = newItem;
    this.slotDate = newItem;
    this.updateAvailabilities();
  }

  updateAvailabilities(first = false): void {
    this.loading = true;
    let filters = '';
    let types = '';
    let sports =
      'aquagym,cardio,danse,muscle-strengthening,relaxation-well-being,team-training';
    if (this.selectedSport && this.selectedSport !== 'fitness') {
      sports = this.selectedSport;
    }

    if (this.filter) {
      filters = this.filter.filters;
      types = this.filter.servicesTypes;
      sports = this.filter.sports;
    }
    this.centersService
      .getCentersAvailabilities(
        this.centerId,
        sports,
        this.selectedDate,
        types,
        filters,
        this.partySize,
        this.endDate
      )
      .subscribe({
        next: (res) => {
          // to make sure to only show availability that start on one day even if it end the next ( ex : start at 23h end at 00h00)
          // when api will be corrected remove them and use : this.servicesSlots = res.data;
          if (!this.fitnessSports.includes(this.selectedSport)) {
            this.servicesSlots = res.data;
          } else {
            this.servicesSlots = res.data.filter(
              (slot) => slot.services && slot.services.length > 0
            );
          }
          this.slotService.emitChange(this.servicesSlots);
          this.loading = false;
          if (this.servicesSlots.length === 0) {
            this.displayAvailabilities = true;
          }
          if (first) {
            const services = this.servicesSlots.filter(
              (e) => e.startDateTime === this.selectedDate
            );
            if (services.length > 0) {
              this.onSlotSelected([
                new ServicesSlots(this.selectedDate, services[0].services)
              ]);
            }
          }
        },
        error: (error) => {
          this.error = error.message;
          this.loading = false;
        }
      });
  }

  onSlotSelected(newItem: ServicesSlots[]): void {
    this.serviceInformation = [];
    if (newItem) {
      this.displayAvailabilities = false;
      this.selectedSlot = newItem;
      this.servicesAvailable = [];
      this.selectedSlot &&
        this.selectedSlot.forEach((slot) => {
          this.servicesAvailable = this.servicesAvailable
            .concat(
              slot.services &&
                slot.services.map((e) => {
                  if (!e['startDateTime']) {
                    e['startDateTime'] = slot.startDateTime;
                  }
                  return e;
                })
            )
            .filter((e) => e && e['id']);
        });
      let ser = this.center.services;

      for (let serviceIn of this.servicesAvailable) {
        let el = ser.find((ele) => ele['id'] == serviceIn['id']);
        if (el) {
          let serviceInofx = new ServiceInformation();
          serviceInofx.id = el['id'];
          this.slotId = serviceInofx.id;
          serviceInofx.name = el.name;
          serviceInofx.price = serviceIn['price'];
          serviceInofx.currency = this.center.currenciesAccepted;
          serviceInofx.charachteristic = this.getCharacteristic(
            el['resourceId']
          );
          serviceInofx.prices = this.getPrices(serviceIn['id']);
          serviceInofx.startTime = serviceIn['startDateTime'];
          serviceInofx.duration = serviceIn['duration']
          serviceInofx.userPrices = serviceIn['userPrices']
          if (serviceIn.availablePlaces) {
            serviceInofx.availablePlaces = serviceIn.availablePlaces;
          }
          if (serviceIn.totalCapacity) {
            serviceInofx.totalCapacity = serviceIn.totalCapacity;
          }
          serviceInofx.description = el.description;

          if (el.photos.length) {
            serviceInofx.photo = el.photos[0]['small'].toString();
          } else {
            serviceInofx.photo = this.center.headerPhoto['420x210'];
          }
          this.serviceInformation.push(serviceInofx);
          this.displayAvailabilities = true;
        }
      }
    } else {
      this.displayAvailabilities = false;
    }

    if (
      this.selectedSlot &&
      (this.selectedSlot.length == 0 || this.selectedSlot.length > 1)
    ) {
      this.updateDateQueryParams(this.selectedDate);
    } else {
      this.updateDateQueryParams(this.selectedSlot[0].startDateTime);
    }
  }

  getCharacteristic(resourceId: string): any {
    if (resourceId === null) {
      return [];
    } else {
      let el = this.center.resources.find((ele) => ele.id == resourceId);
      return el.characteristics;
    }
  }

  getPrices(id: string): any {
    if (id === null) {
      return [];
    } else {
      let el = this.servicesAvailable.find((ele) => ele.prices);
      if (el) {
        return el.prices;
      }
      return [];
    }
  }

  private updateDateQueryParams(params: string): void {
    const navigationExtras: NavigationExtras = {
      relativeTo: this.activateRoute,
      queryParams: { date: params },
      queryParamsHandling: 'merge'
    };
    console.log("merge navigate")
    this.router.navigate([], navigationExtras);
  }

  updateAvailabilitiesWithFilter(filter: FilterContent) {
    this.filter = filter;
    this.loading = true;
    this.centersService
      .getCentersAvailabilities(
        this.centerId,
        filter.sports,
        this.selectedDate,
        filter.servicesTypes,
        filter.filters,
        filter.partySize,
        this.endDate
      )
      .subscribe({
        next: (res) => {
          // to make sure to only show availability that start on one day even if it end the next ( ex : start at 23h end at 00h00)
          // when api will be corrected remove them and use : this.servicesSlots = res.data;
          if (!this.fitnessSports.includes(this.selectedSport)) {
            const format = 'yyyy-MM-dd';
            const locale = 'fr-FR';
            const chosenDate = this.selectedDate
              ? this.selectedDate
              : formatDate(new Date(), format, locale);
            this.servicesSlots = res.data.filter(
              (availabilitie) =>
                formatDate(availabilitie.startDateTime, format, locale) ==
                chosenDate
            ); //
          } else {
            this.servicesSlots = res.data.filter(
              (slot) => slot.services && slot.services.length > 0
            );
          }

          this.slotService.emitChange(this.servicesSlots);
          this.loading = false;
          if (this.servicesSlots.length === 0) {
            this.displayAvailabilities = true;
          }
        },
        error: (error) => {
          this.error = error.message;
          this.loading = false;
        }
      });
  }
}
