import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SharedService } from './service/shared.service';
import { UserPreferenceService } from './service/user-preferences.service';
import { NavigationEnd, Router } from '@angular/router';
import { TagManagerService } from './service/tag-manager.service';
import { AuthFirebaseService } from './service/auth-firebase.service';
import { SeoService } from './service/seo.service';
import { BreakpointService } from '../shared/services/breakpoint.service';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.css']
})
export class MarketplaceComponent {
  title = 'hello Anybuddy';
  deviceInfo = null;
  isMobileResolution: boolean;

  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private userPreferenceService: UserPreferenceService,
    private tagManager: TagManagerService,
    private authFirebaseService: AuthFirebaseService,
    private seoService: SeoService,
    private breakpointService: BreakpointService
  ) {
    this.pushScreenView(this.router.url);
    this.router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        this.pushScreenView(y.urlAfterRedirects);
      }
    });
    this.translate.addLangs(['fr', 'es', 'nl', 'en', 'be', 'ch']);
    this.translate.setDefaultLang('fr');
    this.userPreferenceService.init();

    this.epicFunction();
    this.breakpointService.isMobile().subscribe((isMobile) => {
      this.isMobileResolution = isMobile;
    });
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
    this.sharedService.emitChange(this.isMobileResolution);
    this.seoService.initSeo();
  }

  pushScreenView(screenName: string): void {
    this.tagManager.pushScreenView({
      screenName: screenName,
      user_id: this.authFirebaseService.getUserDataId()
    });
  }

  epicFunction(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  onResize(event): void {
    if (
      event.target.innerWidth <= 768 ||
      this.deviceService.isMobile() ||
      this.deviceService.isTablet()
    ) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
    this.sharedService.emitChange(this.isMobileResolution);
  }

  onActivate(): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
