import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { InfoPaiement } from '../../../models/infoPaiment';
import { Places } from '../../../models/places';
import { Prices } from '../../../models/prices';
import { ServiceInformation } from '../../../models/serviceInformation';
import { ServicesSlots } from '../../../models/slots';
import { MoreDetailsComponent } from '../../../more-details/more-details.component';
import {
  InfoPaiementService,
  SharedService
} from '../../../service/shared.service';
import { UserPreferenceService } from '../../../service/user-preferences.service';
import { BreakpointService } from '../../../../shared/services/breakpoint.service';
import { AuthFirebaseService } from 'src/app/marketplace/service/auth-firebase.service';

@Component({
  selector: 'app-activity-availability-displayer',
  templateUrl: './activity-availability-displayer.component.html',
  styleUrls: ['./activity-availability-displayer.component.scss']
})
export class ActivityAvailabilityDisplayerComponent
  implements OnInit, OnChanges
{
  @Input() servicesSlots: ServicesSlots[] = [];
  @Input() center;
  @Input() selectedSport;
  @Input() serviceInformation;
  @Input() selectedSlot;
  @Input() isWhiteLabel: boolean;
  @Input() loading = false;
  @Input() centerId: string;

  serviceInformationToShow: ServiceInformation[] = [];
  isSimplifiedView = true;
  color = '#FFC300';
  isMobileResolution = true;
  isChangeNbrFromPaiement: boolean;
  canredirect = true;
  currentDay = '';
  isLoggedIn = false;
  servPrice: ServiceInformation;
  prices: Prices[] = [];
  places: Places[] = [];

  feePrice = 0;
  slotId: string;
  placeDispo = 0;
  initial: ServiceInformation;
  today = new Date().toISOString().slice(0, 10);

  constructor(
    private userPreferenceService: UserPreferenceService,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    public infoPaiementService: InfoPaiementService,
    private router: Router,
    private breakpointService: BreakpointService,
    private authFirebaseService: AuthFirebaseService
  ) {
    this.breakpointService.isMobile().subscribe((isMobile) => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution =
      this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe((isMobileResolution) => {
      this.isMobileResolution = isMobileResolution;
    });
    this.isLoggedIn = this.authFirebaseService.isLoggedIn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSlot !== undefined) {
      this.selectedSlot = changes.selectedSlot.currentValue;
    }

    if (
      changes.serviceInformation !== undefined &&
      changes.serviceInformation.currentValue
    ) {
      this.serviceInformation = changes.serviceInformation.currentValue;
      this.serviceInformationToShow = this.getService();
    }
  }

  ngOnInit(): void {
    if (this.servicesSlots === undefined) {
      this.servicesSlots = [];
    }
    this.isChangeNbrFromPaiement = false;
    this.serviceInformationToShow = this.getService();
  }

  openSessionDescriptionModal(session: any): void {
    const modalRef = this.modalService.open(MoreDetailsComponent, {
      size: 'xl',
      centered: true
    });
    modalRef.componentInstance.session = session;
    modalRef.componentInstance.selectedSport = this.selectedSport;
    modalRef.componentInstance.onBookOrChooseTarif.subscribe(() => {
      this.modalService.dismissAll();
      this.onBookActivity(session, this.selectedSlot);
    });
  }

  // quand l'utilisateur appuie sur le bouton "réserver" d'un des services présentés reservationDetails/
  onBookActivity(service, selectedSlot): void {
    console.log(
      'this.authService.isLoggedIn',
      this.authFirebaseService.isLoggedIn
    );
    if (this.authFirebaseService.isLoggedIn) {
      this.onBookActivityAction(service, selectedSlot);
    } else {
      const slotSelected = selectedSlot.filter(
        (slots) => slots.startDateTime === service.startTime
      )[0];

      const serviceId = slotSelected.services.filter(
        (s) =>
          s.id === service.id &&
          s.duration === service.duration &&
          s.price === service.price
      );
      const selectedService = {
        startDateTime: slotSelected.startDateTime,
        services: [...serviceId]
      };

      sessionStorage.setItem('slots', JSON.stringify(selectedService));
      this.router.navigate(['/login'], {
        queryParams: { redirectUrl: this.router.url }
      });
    }
  }

  onBookActivityAction(service, selectedSlot): void {
    const slotSelected = selectedSlot.filter(
      (slots) => slots.startDateTime === service.startTime
    )[0];
    const serviceId = slotSelected.services.filter(
      (s) =>
        s.id === service.id &&
        s.duration === service.duration &&
        s.price === service.price
    );
    const selectedService = {
      startDateTime: slotSelected.startDateTime,
      services: [...serviceId]
    };
    const serviceIdUse = selectedService.services[0].id;
    const duration = selectedService.services[0]['duration'];
    const startDateTime = selectedService.startDateTime;

    sessionStorage.removeItem('slots');
    sessionStorage.setItem('slots', JSON.stringify(selectedService));
    this.feePrice = selectedService.services[0].serviceFee;
    sessionStorage.setItem('activities', this.selectedSport);
    if (
      selectedService.services[0].prices &&
      selectedService.services[0].prices.length > 0 &&
      (typeof selectedService.services[0].availablePlaces !== 'undefined' ||
        selectedService.services[0].availablePlaces !== null)
    ) {
      //console.log("enter toto !!")
      console.log(selectedService)
      localStorage.setItem('join', JSON.stringify({'join' : false}));
      this.placeDispo = selectedService.services[0].availablePlaces;
      this.servPrice = selectedService.services[0];
      this.initial = selectedService.services[0];
      sessionStorage.removeItem('selectedService');
      sessionStorage.setItem('selectedService', JSON.stringify(selectedService));
      const infoPaiement = new InfoPaiement();
      infoPaiement.centerId = this.centerId;
      infoPaiement.serviceId = selectedService.services[0].id;
      infoPaiement.slotId = selectedService.services[0].slotId;
      infoPaiement.price = selectedService.services[0].price;
      infoPaiement.places = [];
      this.infoPaiementService.servPrice = infoPaiement;
      sessionStorage.removeItem('infoPaiement');
      sessionStorage.setItem('infoPaiement', JSON.stringify(infoPaiement));
      this.servPrice = selectedService.services[0].prices;
      const country = this.userPreferenceService.getCountry();
      const countryPath = country !== 'fr' ? `/${country}` : '';
      const fullRoute = `${countryPath}/checkout/${this.centerId}/service/${serviceIdUse}`;

      this.router.navigate([fullRoute], {
        state: infoPaiement,
        queryParams: {
          duration: duration,
          startDateTime: startDateTime
        }
      });
    } else {
      const infoPaiement = new InfoPaiement();
      infoPaiement.centerId = this.centerId;
      infoPaiement.serviceId = selectedService.services[0].id;
      infoPaiement.slotId = selectedService.services[0].slotId;
      infoPaiement.price = selectedService.services[0].price;
      infoPaiement.places = [];
      localStorage.setItem('join', JSON.stringify({'join' : false}));
      this.infoPaiementService.servPrice = infoPaiement;
      sessionStorage.removeItem('infoPaiement');
      sessionStorage.setItem('infoPaiement', JSON.stringify(infoPaiement));
      this.servPrice = selectedService.services[0].prices;
      const country = this.userPreferenceService.getCountry();
      const countryPath = country !== 'fr' ? `/${country}` : '';
      const fullRoute = `${countryPath}/checkout/${this.centerId}/service/${serviceIdUse}`;
      console.log(infoPaiement);
      this.router.navigate([fullRoute], {
        state: infoPaiement,
        queryParams: {
          duration: duration,
          startDateTime: startDateTime
        }
      });
    }
  }

  isOtherDay(session: any): boolean {
    const date = session.startTime.split('T')[0];
    if (this.currentDay === '') {
      this.currentDay = date;
    }
    if (this.currentDay === date) {
      return false;
    } else {
      this.currentDay = date;
      return true;
    }
  }

  isToday(startTime: any): boolean {
    return this.today === startTime.slice(0, 10);
  }

  getDisplayName(session: any): string {
    if (this.isWhiteLabel) {
      return this.camelize(this.selectedSport);
    }
    return session.name;
  }

  camelize(str: string): string {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return '';
    }
  }

  onViewSwitch(event: Event) {
    this.isSimplifiedView = !this.isSimplifiedView;
    this.serviceInformationToShow = this.getService();
  }
  getService() {
    if (this.isWhiteLabel) {
      return this.getServiceInformation(this.serviceInformation);
    } else {
      return this.isSimplifiedView
        ? this.getServiceInformation(this.serviceInformation)
        : this.serviceInformation;
    }
  }
  getServiceInformation(services: ServiceInformation[]): ServiceInformation[] {
    const groupedServices: ServiceInformation[] = [];

    services.forEach((service) => {
      // Vérifier si un service avec les mêmes caractéristiques existe déjà dans groupedServices
      const existingService = groupedServices.find((s) => {
        return (
          s.price === service.price &&
          s.duration === service.duration &&
          s.startTime === service.startTime &&
          JSON.stringify(s.charachteristic) ===
            JSON.stringify(service.charachteristic)
        );
      });
      // S'il n'y a pas de service existant avec les mêmes caractéristiques, ajouter le service à groupedServices
      if (!existingService) {
        groupedServices.push(service);
      }
    });
    return groupedServices;
  }
  handleCloseModalEvent(event: boolean) {
    this.canredirect = event;
  }

  redirectToLogin(): void {
    this.router.navigate(['/login'], {
      queryParams: { redirectUrl: this.router.url }
    });
  }

  getPrice(session: ServiceInformation): number {
    if(session.userPrices && session.userPrices.length > 0){
      return session.userPrices[0].price
    } else {
      return session.price
    }

  }
}
