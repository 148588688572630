import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFirebaseService } from '../../marketplace/service/auth-firebase.service';
import { TagManagerService } from '../../marketplace/service/tag-manager.service';

@Component({
  template: `<h1>Logout</h1>`
})
export class LogoutComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platform,
    private router: Router,
    private authFirebaseService: AuthFirebaseService,
    private tagManager: TagManagerService
  ) {}

  ngOnInit(): void {
    if (
      isPlatformBrowser(this.platform) &&
      this.authFirebaseService.getIsLoggedIn
    ) {
      this.tagManager.pushLogout({
        user_id: this.authFirebaseService.getUserDataId()
      });
      this.authFirebaseService._signOut();
    }
    this.router.navigate(['/login']);
  }
}
