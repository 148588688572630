import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MatchData } from '../models/matchData';
import { MatchUpdateTeam } from '../models/matchUpdate';
import { PaginatedMatch } from '../models/paginated-match';
/* eslint-disable */
@Injectable({
  providedIn: 'root'
})
export class MatchService {
  private matchUrl = environment.bookingApiUrl + '/v1/matchs';

  constructor(private http: HttpClient) {}

  joinMatch(
    matchId: string,
    token?: string,
    referralUserCode?: string
  ): Observable<MatchData> {
    let params = new HttpParams();
    if (token) {
      params = params.append('token', token);
    }
    if (referralUserCode) {
      params = params.append('referralUserCode', referralUserCode);
    }
    return this.http.post<MatchData>(`${this.matchUrl}/${matchId}`, null, {params});
  }

  getMatch(
    matchId: string,
    token?: string,
    referralUserCode?: string
  ): Observable<MatchData> {
    let params = new HttpParams();
    if (token) {
      params = params.append('token', token);
    }
    if (referralUserCode) {
      params = params.append('referralUserCode', referralUserCode);
    }
    return this.http.get<MatchData>(`${this.matchUrl}/${matchId}`, {params});
  }

  leaveMatch(matchId: string): Observable<any> {
    return this.http.patch<any>(`${this.matchUrl}/${matchId}/quit`, null);
  }

  updateMatch(matchId: string, data: MatchUpdateTeam): Observable<MatchData> {
    return this.http.put<MatchData>(
      `${this.matchUrl}/${matchId}`,
      JSON.stringify(data)
    );
  }

  getMatchShareLink(matchId: string): Observable<any> {
    return this.http.get(`${this.matchUrl}/${matchId}/share`);
  }

  resetMatchLink(matchId: string): Observable<any> {
    return this.http.patch(`${this.matchUrl}/${matchId}/share/reset`, null);
  }

  unShareMatchLink(matchId: string): Observable<any> {
    return this.http.patch(`${this.matchUrl}/${matchId}/unshare`, null);
  }
  getMatchs(
    userId: string,
    limit?: number,
    before?: number,
    after?: number
  ): Observable<PaginatedMatch> {
    let params = new HttpParams();
    params = params.append('userId', userId);
    if (limit) {
      params = params.append('limit', limit.toString());
    }
    if (before) {
      params = params.append('before', before.toString());
    }
    if (after) {
      params = params.append('after', after.toString());
    }
    return this.http.get<PaginatedMatch>(
      `${environment.bookingApiUrl}/v1/matchs`,
      { params: params }
    );
  }

  getMatchByUri(uri: string): Observable<PaginatedMatch>{
    //const usri = "http://localhost:9010/v1/matchs?userId=me&limit=10&after=2"
    //uri = "s"
    return this.http.get<PaginatedMatch>(uri)
  }

  getmatchUrlLink(matchId: string) {
    return this.http.get<any>(`${this.matchUrl}/${matchId}/share`)
  }

}
