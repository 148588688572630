<ng-container>
  <app-loader *ngIf="isLoading || (!hasMatchId && matchIdStarted)" class="custom-loader"></app-loader>
</ng-container>

<div class="paiement-container" *ngIf="center">
  <div class="back-arrow" (click)="back()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z" />
    </svg>
  </div>

  <h3 class="recap-title">{{ 'checkout.summary_payments' | translate }}</h3>

  <div class="row container-content">
    <div class="col-md-8 col-lg-6">

      <hr class="line d-block d-lg-none">

      <!-- Message important -->
      <div class="important-info-container">
        <h5 class="title">🚨 {{'checkout.important_msg' | translate }}</h5>
        <div *ngIf="importantMsg"  class="content" [innerHtml]="importantMsg"></div>
        <div *ngIf="paymentInClub" class="content" [innerHtml]="('checkout.pay_in_club_htmlText' | translate)"></div>
        <br *ngIf="importantMsg || paymentInClub">
        <div class="confirmationCheck" *ngIf="center.instantBook">
          {{ 'checkout.instant_confirmation' | translate }}
        </div>
        <div class="confirmationCheck"  *ngIf="!center.instantBook">
          {{ 'checkout.not_instant_confirmation' | translate }}
          <span class="font-italic underline" *ngIf="center.averageResponseTimeString">
            {{"checkout.not_instant_confirmation_average_time" | translate : { averageResponse: center.averageResponseTimeString } }}
          </span>
        </div>
      </div>
      <hr *ngIf="importantMsg || paymentInClub" class="line">

      <!-- Message d'annulation reservation -->
      <div class="cancelation py-1">
        <h5 class="title">{{'checkout.cancelation_policy' | translate}}</h5>
        <div class="confirmationCheck pb-1">{{ cartItem.data.cancellationCondition }}</div>
        <a class="cancel-text" *ngIf="!isWhiteLabel" href="https://support.anybuddyapp.com/fr/article/quelles-sont-les-conditions-dannulation-avant-la-partie-1dzp8uv/" target="_blank">
          {{ 'checkout.cancelation_policy_know_more' | translate }}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"/></svg>
        </a>
      </div>
      <hr class="line">

      <!-- Section Choix Match-->
      <div class="choice-match" *ngIf="this.isConfigSimpleDouble">
        <div class="match-title">
          <h5>{{'checkout.game' | translate}} </h5>
        </div>
        <div class="d-flex justify-content-between">
          <button class="btn-simple" [ngClass]="{selected: this.buttonSimpleDouble == 'simple'}" (click)="this.selectButtonSimpleDouble('simple')">
            <span>{{'checkout.single' | translate}}</span>
          </button>
          <button class="btn-double" [ngClass]="{selected: this.buttonSimpleDouble == 'double'}" (click)="this.selectButtonSimpleDouble('double')" >
            <span>{{'checkout.double' | translate}}</span>
          </button>
        </div>
      </div>
      <hr class="line" *ngIf="this.isConfigSimpleDouble">
      <!--Fin Section Choix Match-->

      <!--Section Choix Tout Payer Ou Payer Sa Part-->
      <div class="paiment-choice">
        <div class="match-title">
          <h5>{{'checkout.choice_of_payment_title' | translate}}</h5>
        </div>

        <div class="container">
          <div class="row mt-2 d-flex justify-content-end" *ngIf="this.singlePayerSupported">
            <div class="d-flex">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="payAll" (click)="handleClick(false)" [checked]="!isPayOnlyMine">
              <div class="ms-3 text-pay-all">
                {{'checkout.choice_all' | translate}}
              </div>
            </div>
          </div>
          <div class="row mt-2 d-flex justify-content-end" *ngIf="this.splitSupported">
            <div class="d-flex">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="payOnlyMine" (click)="handleClick(true)" [checked]="isPayOnlyMine">
              <div class="ms-3 text-pay-only-mine">
                {{'checkout.choice_my_part_or_more' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="form-check" *ngIf="this.splitSupported && isPayOnlyMine && payMissingShares">
          <label >{{ 'checkout.pay_missing_shares' | translate }}</label>
        </div>
      </div>
      <hr class="line">
      <!--Fin Section Choix Tout Payer Ou Payer Sa Part-->

      <!-- Detail prix -->
      <h5 class="title">{{ 'checkout.price_details' | translate }}</h5>
      <div *ngIf="!isMultiPrices" class="details-price-container d-flex justify-content-between">
        <span >{{ cartItem.name }} </span>
        <span class="detailSpan ">{{total | currency: center.currenciesAccepted:true}}</span>
      </div>

      <!-- Multi prix -->
      <div class="multi-price-details" *ngIf="isMultiPrices">
        <div *ngFor="let place of placesSelected" class="place-list">
          <div class="place" *ngIf="place.nbPlace > 0">
            <div >{{place.nbPlace + 'x ' + place.name + ' ' + (place.price/100|currency:center.currenciesAccepted:true )}}</div>
            <div>{{(place.nbPlace * place.price)/100|currency:center.currenciesAccepted:true}}</div>
          </div>
        </div>
        <div class="place-taken pt-3">
          <div>{{'checkout.placetaken' | translate}} {{bookedPlaces}}</div>
          <div *ngIf="!(isPayOnlyMine && servPrice.userPrices)">
            <span class="modify-nb-participants" (click)='isTarifPopup(tarifContent)'>{{'checkout.edit' | translate}}</span>
          </div>
        </div>
      </div>

      <!-- Code promo -->
      <div class="promo-container" *ngIf="!isCE && !isCenterMember && isWhiteLabel===false">
        <div>{{ 'checkout.code_promo' | translate }}</div>

        <div><a class="addCodePromo" (click)="openModalPromo(content)"> {{pricePromo}}</a></div>
      </div>

      <!-- New -->

      <div>
        <div class="useMyCodes">
          <div *ngIf="cartData.vouchers">
            <div *ngFor="let voucher of cartData.vouchers" class="vouchers detailPrice d-flex justify-content-between">
              <span>{{ voucher.name }}</span>
              <span>{{ this.convertStringToPrice(voucher.amount) |currency:center.currenciesAccepted:true}}</span>
            </div>
          </div>
        </div>

        <div class="service-fees" *ngIf="this.fees != 0">
          <div class="fee-content">
            <div class="fee-label" (click)="openFeeInfoModal(feeInfo)">
              <p class="title-fees">{{ 'checkout.serviceFee_text' | translate }}</p>
              <svg class="fee-InfoBtn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" />
              </svg>
            </div>
            <div class="fee-price" >
              <p class="price">{{ fees |currency:center.currenciesAccepted:true}}</p>
            </div>
          </div>
        </div>

        <div class="useMyWallet" *ngIf="this.walletAmount != 0">
          <div class="detailPrice d-flex justify-content-between">

            <span>{{ 'checkout.wallet' | translate }}</span>
            <span>{{ walletAmount |currency:center.currenciesAccepted:true}}</span>
          </div>
        </div>

        <hr class="line">

        <div class="totalClassTitle">{{ 'checkout.total' | translate }}<span class="totalClassInfo">
        {{ amountToBePaid | currency:center.currenciesAccepted:true }}</span></div>

        <hr class="line" >

        <div class="ask-help" *ngIf="!isWhiteLabel">
          <a href="https://go.crisp.chat/chat/embed/?website_id=9b5110a7-ec05-4ef0-8d28-d9dad2348bd8" target="_blank">
            {{ 'checkout.ask_help' | translate }}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"/></svg>
          </a>
        </div>

        <div class="form-check">
          <input type="checkbox" class="check" id="checkboxConfirmation" value="1" (change)="onCheckChange($event)">
          <label class="checkbox-label" for="checkboxConfirmation">{{ 'checkout.select_button_below' | translate }}
            <a *ngIf="!isWhiteLabel" [href]="linkCGV" target="_blank" class="hrefCheck"> {{ 'checkout.general_conditions_reservation' | translate }}</a>
            <a *ngIf="isWhiteLabel" [href]="center.rulesUrl ? center.rulesUrl : ''" target="_blank" class="hrefCheck"> {{ 'checkout.general_conditions_reservation_partner' | translate : {partner: center.name} }}</a>
          </label>
        </div>
        <p *ngIf="error && !isErrorServer()" class="errorMsg"> {{ 'checkout.error_forget_accept_conditions' | translate }} </p>
        <p *ngIf="error && isErrorServer()" class="errorMsg"> {{ errorMessage }} </p>

        <button [disabled]="error" *ngIf="(cartData.paymentDetails.amountToBePaid !== 0 || (cartData.paymentDetails.amountToBePaid === 0 && cartItem.completePaymentMissingShare)) && !paymentInClub" class="rcorners" (click)="payer(mymodal, emailVerification)" [ngClass]="{'btn-marginTop': !error}">
          {{ (cartData.paymentDetails.amountToBePaid === 0 && cartItem.completePaymentMissingShare ?'checkout.booked' :'checkout.pay') | translate }}
        </button>
        <button [disabled]="errorMessage && error && !isConfirmationCheck()" *ngIf="(cartData.paymentDetails.amountToBePaid === 0 || paymentInClub) && !cartItem.completePaymentMissingShare" class="rcorners" (click)="checkout()" [ngClass]="{'btn-marginTop': !error}">
          <div *ngIf="loadVALUE" class="spinner-border text-light" role="status">
            <span class="sr-only"></span>
          </div> {{ (cartData.paymentDetails.amountToBePaid === 0 ?'checkout.booked' :'checkout.pay_in_club') | translate }}
        </button>
        <hr class="infoServiceEnd">
      </div>


    </div>

    <div class="col-lg-6 card-content">
      <div class="club-container">
        <div class="info">
          <div class="club-img-container">
            <img class="serviceImg" [src]="photoService" height="120" width="120" />
          </div>
          <div class="info-container">
            <div class="clubInfo">{{ center.name }}</div>
            <div class="adressClub">{{ center.address.city }} {{ center.address.postalCode }} </div>
          </div>
        </div>
        <div class="serviceDate">{{ cartItem.data.dateFMT | titlecase }}</div>

      </div>

    </div>

  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <div class="cross-container">
        <span aria-hidden="true" class="cross">&times;</span>
      </div>
    </button>
  </div>
  <div class="modal-body">
    <app-promo-code
      [modal]="modal" (modalchoose)="openModal($event)"
      (voucher)="applyVoucher($event)"
      [startDate]="startDate" [duration]="duration.toString(10)"
      [price]="total.toString(10)" [activity]="activity"
    ></app-promo-code>
  </div>
</ng-template>

<ng-template #promoContent let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <div class="cross-container">
        <span aria-hidden="true" class="cross">&times;</span>
      </div>
    </button>
  </div>
  <div class="modal-body" *ngIf="!isWhiteLabel">
    <app-external-code [imgPartner]="imgPartner" [partner]="partner" [activity]="activity"  [price]="cartData.paymentDetails.price"
                       [startDate]="startDate" [isPayOnlyMine]="isPayOnlyMine" [duration]="duration.toString(10)" [centerId]="centerId" (voucher)="applyVoucher($event)">
    </app-external-code>
  </div>

</ng-template>

<!-- MODAL -->
<ng-template #mymodal let-modal>
  <div class="modal-header">
    <button type="button" style="
        background: white;
        border: aliceblue;
    " aria-label="Close" (click)="modal.dismiss('Cross click')"> <img ngSrc="v1677169402/assets/arrow/arrow-left-line.png"
                                                                      class="icon-nav" width="36" height="36"></button>
  </div>
  <div class="modal-body">
    <app-stripe-component [newReservation]="newReservationModel" [cartId]="cartData.id"
                          [center]="center" [service]="service" [duration]="duration"
                          [date]="startDate" [isWhiteLabel]="isWhiteLabel" [cards]="cards"
                          [showTextBook]="cartData.paymentDetails.amountToBePaid === 0 && cartItem.completePaymentMissingShare">
    </app-stripe-component>
  </div>
</ng-template>


<!-- Modal pour choisir les tarifs  -->

<ng-template #tarifContent let-modal>
  <ng-container>
    <div class="row">
      <div class="col-xs-12 mt-3 ms-4">
        <nav class="custom-navbar">
          <div class="col" (click)="modal.close('Close click')">
            <img class="navbar-icon" ngSrc="v1677169402/assets/arrow/arrow-left-line.png" width="36" height="36" alt="Menu">
            <i class="fas fa-arrow-left"></i>
          </div>
        </nav>
      </div>
    </div>

    <app-multiple-prices
      [servPrice]="servPrice"
      [center]="center"
      [startReset]="true"
      [placesSelected]="placesSelected"
      (newPlacesSelected)="newPlacesSelected($event)"
    >
    </app-multiple-prices>

  </ng-container>
</ng-template>

<!-- Modal pour les frais -->

<ng-template #feeInfo let-modal style="height: auto">
  <div class="modal-header fee-modal-header">
    <div class="col" style="display: flex">
      <h2>{{ 'checkout.serviceFee_text' | translate }}</h2>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fee-modal-body">
    <div class="col">
      <p class="fee-modal-text">{{ cartItem.serviceFeeText }}</p>
    </div>
  </div>
</ng-template>

<!-- modal for email verification -->
<ng-template #emailVerification>
  <div class="modal-content">
    <div class="modal-body email-modal-body">
      <div class="email-modal-header">
        <button type="button" class="close" (click)="closeEmailModal()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg>
        </button>
      </div>

      <!-- Prenier modal -->
      <div  *ngIf="!emailSent" class="email-modal-content">
        <div class="title-container">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11v6h2v-6h-2zm0-4v2h2V7h-2z"/></svg> -->
          <h5 class="title">{{ "email_validator.title_first_modal" | translate }}</h5>
          <p class="subtitle">{{ "email_validator.subtitle_first_modal" | translate }}</p>
        </div>
        <div class="d-flex mt-2 mb-2">
          <span class="m-auto">{{userEmail}}</span>
        </div>
        <div class="email-form">
          <button type="submit" (click)="onSubmitEmailValidatorForm()" class="form-control form-submit btn-submit">{{ "email_validator.email_btn_send" | translate }}</button>
        </div>
      </div>

      <!-- Deuxieme modal -->
      <div *ngIf="emailSent" class="email-modal-content">
        <div class="title-container">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11v6h2v-6h-2zm0-4v2h2V7h-2z"/></svg> -->
          <h5 class="title">{{ "email_validator.title_second_modal" | translate }}</h5>
          <p class="subtitle">{{ "email_validator.subtitle_second_modal" | translate }}</p>
        </div>

        <ng-container *ngIf="!emailVerified">
          <div class="email-button-container">
            <button class="send-email" (click)="resendCodeEmail()">{{ "email_validator.resend_mail_btn" | translate }}</button>
            <div *ngIf="resendEmail">
              <p><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="rgba(255,195,0,1)"/></svg>
                {{ "email_validator.resend_mail_text" | translate }}</p>
            </div>
          </div>
        </ng-container>
      </div>

    </div>
  </div>

</ng-template>
<!-- Modal pour erreur pour les resa avec moin d'une place -->

<ng-template #resaError let-modal style="height: auto">
  <div class="modal-header">
    <h2>{{ 'checkout.resa_error_place_title' | translate }}</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col">
      <p class="text-center">{{ 'checkout.resa_error_place_content' | translate }}</p>
    </div>
  </div>
</ng-template>
